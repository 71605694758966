import React from 'react';
import PropTypes from 'prop-types';

import TextCell from 'app/components/data-table/TextCell';

export default function SchemaCell({ schema }) {
  return <TextCell text={schema} />;
}

SchemaCell.propTypes = {
  schema: PropTypes.string.isRequired,
};
