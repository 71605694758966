import { gql } from '@apollo/client';

export const removeSchemaLibraryMutation = gql`
  mutation removeSchemaLibrary($id: uuid!) {
    update_schema_libraries_by_pk(pk_columns: { id: $id }, _set: { deletedAt: "now()" }) {
      id
    }
  }
`;

export function buildVariables({ id }) {
  return {
    id,
  };
}
