import actions from 'api/audit-logs/actions';
import { generateTimestamp } from 'api/audit-logs/generate-timestamp';

export function buildAuditLog({ eventSchemaId, name, description }) {
  const action = actions.event_schema_deleted;
  const debug_data = {
    eventSchemaId,
    name,
    description,
  };
  const timestamp = generateTimestamp();

  return {
    timestamp,
    action,
    description: name,
    debug_data,
  };
}
