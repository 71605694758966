import { useEffect, useState } from 'react';
import compareRemoteFileContentToString from './compareRemoteFileContentToString';
import { HOSTING_TYPES } from './analytics-builder-file-hosting';

/**
 * A custom React hook that compares a generated file with its remote counterpart.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {string} params.generatedFile - The content of the locally generated file.
 * @param {string} params.remoteFileLocation - The URL or location of the remote file.
 * @param {string} params.hostingType - The type of hosting, should be one of HOSTING_TYPES.
 *
 * @returns {Object} An object containing the comparison result.
 * @returns {boolean} returns.isUpdated - True if the generated file is different from the remote file, false otherwise.
 * @returns {string} returns.error - An error message if the comparison fails, empty string otherwise.
 *
 * @example
 * const { isUpdated, error } = useIsGeneratedFileUpdated({
 *   generatedFile: '// Some code',
 *   remoteFileLocation: 'https://example.com/file.js',
 *   hostingType: HOSTING_TYPES.metaRouter
 * });
 */
const useIsGeneratedFileUpdated = ({ generatedFile, remoteFileLocation, hostingType }) => {
  const [isUpdated, setIsUpdated] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // if not metarouter hosted, we don't need to do the comparison
    if (hostingType !== HOSTING_TYPES.metaRouter) return;
    const doCompare = async () => {
      try {
        const isSame = await compareRemoteFileContentToString(remoteFileLocation, generatedFile);
        setIsUpdated(!isSame);
      } catch (e) {
        setError('Unable to complete file comparison');
      }
    };

    doCompare();
  }, [generatedFile, remoteFileLocation, hostingType]);

  return { isUpdated, error };
};

export default useIsGeneratedFileUpdated;
