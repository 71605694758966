import useMutation from 'api/hooks/useMutation';
import permissions from 'api/Apollo/permissions';
import { mutation, buildVariables } from './update-schema-library-mutation-builder';

export default function useUpdateSchemaLibrary(options = {}) {
  const [executeMutation, response] = useMutation(mutation, {
    ...options,
    context: { permission: permissions.updateSchemas },
  });

  const updateSchemaLibraryMutation = ({ id, name, description }) => {
    return executeMutation({
      variables: {
        ...buildVariables({
          id,
          name,
          description,
        }),
      },
    });
  };

  return [updateSchemaLibraryMutation, response];
}
