import React from 'react';
import pt from 'prop-types';

import s from './index.module.scss';

import { Typography } from '@metarouter-private/mr-mui';

import { MrTable, MrAppMain, MrTwoPaneLayout } from '@ion/components';

import IntegrationName from 'app/components/integrations/IntegrationName';

import AssignedPipelinesTableBody from './AssignedPipelinesTableBody';
import OverviewTableRow from './OverviewTableRow';
import StarterKitVersion from './StarterKitVersion';
import Created from './Created';

const TYPOGRAPHY_HEADER_PROPS = {
  variant: 'h5',
  gutterBottom: true,
};

const Overview = ({ integration, playbook }) => {
  return (
    <MrTwoPaneLayout
      className={s.commonTable}
      paneOne={
        <>
          <Typography {...TYPOGRAPHY_HEADER_PROPS}>Details</Typography>
          <MrTable className={s.details}>
            <tbody>
              <OverviewTableRow firstCell="Created" secondCell={<Created createdAt={integration.createdAt} />} />
              <OverviewTableRow
                firstCell="Integration"
                secondCell={<IntegrationName id={integration.starterkitId} />}
              />
              <OverviewTableRow
                firstCell="Starter Kit Version"
                secondCell={<StarterKitVersion starterkitVersion={playbook.version} />}
              />
            </tbody>
          </MrTable>
        </>
      }
      paneTwo={
        <MrAppMain>
          <Typography {...TYPOGRAPHY_HEADER_PROPS}>Assigned Pipelines</Typography>
          <div className={s.scrollable}>
            <MrTable className={s.assigned}>
              <thead>
                <tr>
                  <th> Pipeline</th>
                  <th> Revision</th>
                </tr>
              </thead>

              <AssignedPipelinesTableBody playbooks={integration.playbooks} />
            </MrTable>
          </div>
        </MrAppMain>
      }
    />
  );
};

Overview.propTypes = {
  integration: pt.object.isRequired,
  playbook: pt.object,
};
export default Overview;
