import { gql } from '@apollo/client';
import permissions from '../../Apollo/permissions';
import { useMutation } from '../../index';

const MUTATION = gql`
  mutation RemoveOverrideOrganization {
    auth0_remove_override_organization {
      status
    }
  }
`;

export default function RemoveOverrideOrganization({ onCompleted, ...options } = {}) {
  const [callMutation, response] = useMutation(MUTATION, {
    context: { permission: permissions.overrideOrganization },
    onCompleted: data => {
      if (onCompleted) {
        onCompleted(getResponseBody({ data }));
      }
    },
    ...options,
  });

  function getResponseBody(response) {
    return response.data.auth0_remove_override_organization;
  }

  return [callMutation, response];
}
