import React from 'react';

import { FormModal, Edit03Icon } from '@metarouter-private/mr-mui';

import SchemaLibraryForm from 'app/schema-enforcement/SchemaLibrariesTab/modals/shared/SchemaLibraryForm';
import useEditSchemaLibraryModal from './useEditSchemaLibraryModal';

const MODAL_CONFIG = {
  title: 'Edit Schema Library',
  TitleIcon: Edit03Icon,
  submitLabel: 'Edit Schema Library',
};

export default function EditSchemaLibraryModal() {
  const { name, description, showEditModal, onClose, onSubmit } = useEditSchemaLibraryModal();

  return (
    <FormModal open={showEditModal} onClose={onClose} onSubmit={onSubmit} {...MODAL_CONFIG}>
      <SchemaLibraryForm name={name} description={description} />
    </FormModal>
  );
}
