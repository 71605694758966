import { useMutation as useMrMutation } from '../../index';
import permissions from '../../Apollo/permissions';
import { query as clustersQuery } from '../../clusters/useQueryClusters';
import { query as pipelinesQuery } from '../useQueryPipelines';
import { some } from 'lodash';
import { useUserContext } from '@ion/user';

export default function useMutationWithBaseOptions(mutation, options = {}) {
  const { user, currentOrg } = useUserContext();
  const userData = { email: user?.email, org: currentOrg?.display_name };
  return useMrMutation(mutation, {
    ...options,
    refetchQueries: [
      {
        query: clustersQuery,
        context: { permission: permissions.createPipeline },
      },
    ],
    // makes sure all queries included in refetchQueries are completed before the mutation is considered complete
    awaitRefetchQueries: true,
    update: buildUpdateCache(userData),
  });
}

function buildUpdateCache(userData) {
  return (cache, { data }) => {
    try {
      const { pipelines: cachedPipelines } = cache.readQuery({ query: pipelinesQuery });

      if (!cachedPipelines) return;

      const newPipeline = data.insert_pipelines_one;

      if (!newPipeline) return;

      // ND: TODO - This shouldn't be in the `updateCache` function
      track({ newPipeline, userData });

      const alreadyInCache = some(cachedPipelines, cachedPipeline => cachedPipeline.id === newPipeline.id);

      if (alreadyInCache) return;

      cache.writeQuery({
        query: pipelinesQuery,
        data: {
          pipelines: [newPipeline, ...cachedPipelines],
        },
      });
    } catch (e) {
      console.log(e);
      // We should always catch here,
      // as the cache may be empty or the query may fail
    }
  };
}

function track({ newPipeline, userData }) {
  __mrTracking?.track('pipeline_created', {
    id: newPipeline.id,
    name: newPipeline.name,
    writekey: newPipeline.writekey,
    clusterId: newPipeline.clusterId,
    userEmail: userData.email,
    orgName: userData.org,
  });
}
