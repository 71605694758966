import { gql } from '@apollo/client';

const schemaLibraryVersion = gql`
  fragment schemaLibraryVersion on schema_library_versions {
    id
    schemaLibraryId
    status
    version
  }
`;

export default schemaLibraryVersion;
