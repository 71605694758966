import React from 'react';
import { Box } from '@metarouter-private/mr-mui';
import SchemaViolationsDetailsTable from './SchemaViolationsDetailsTable';

export default function SchemaViolationsDetailsTableSection() {
  return (
    <Box padding={2}>
      <SchemaViolationsDetailsTable />
    </Box>
  );
}
