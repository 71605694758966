import { useMutation } from '../index';
import { buildVariables, mutation } from './dashboard-proxy-mutation-builder';
import permissions from '../Apollo/permissions';
import { diffInDays } from '@ion/mrdash';
import { validate } from 'parameter-validator';
import useTrackWithUser from '../hooks/useTrackWithUser';

export default ({ onCompleted, ...options }) => {
  const [trackWithUser] = useTrackWithUser('dashboard_viewed');
  const [call, response] = useMutation(mutation, {
    onCompleted: r => {
      if (onCompleted && r.dashboardProxy?.data) onCompleted(JSON.parse(r.dashboardProxy.data));
    },
    context: { permission: permissions.readCluster },
    ...options,
  });

  const callDashboardProxy = ({ ...args }) => {
    const { integrationIds } = args;
    const { clusters, pipelines, dateRange, query } = validate(args, ['clusters', 'pipelines', 'dateRange', 'query']);
    const variables = buildVariables({
      clusters,
      pipelines,
      integrationIds,
      dateRange,
      query,
    });

    if (variables.dateRange.since > variables.dateRange.until) {
      return;
    }

    return call({
      variables,
    }).then(response => {
      if (!response.errors && (query === 'Integration' || query === 'Pipeline')) {
        const { filterCount, defaultFilter } = args.track;
        const { dateRange, query } = variables;
        const since = new Date(dateRange.since);
        const until = new Date(dateRange.until);
        const days = diffInDays(since, until, 'days');

        trackWithUser({
          filterCount,
          defaultFilter,
          timestamps: `${dateRange.since} - ${dateRange.until}`,
          diffInDays: days,
          groupedBy: query,
        });
      }
      return response;
    });
  };

  return [callDashboardProxy, response];
};
