import { useMutation } from 'api';
import permission from 'api/Apollo/permissions';
import { mutation, buildVariables as buildUpdateEventName } from './update-event-name-mutation-builder';

export default function useUpdateEventName(options = {}) {
  const [executeMutation, response] = useMutation(mutation, {
    ...options,
    context: { permission: permission.updateEventNames },
  });

  const updateEventNameMutation = ({ id, name, description }) => {
    return executeMutation({
      variables: {
        ...buildUpdateEventName({
          id,
          name,
          description,
        }),
      },
    });
  };

  return [updateEventNameMutation, response];
}
