import React from 'react';
import PropTypes from 'prop-types';
import base64 from 'base-64';
import stringify from 'json-stringify-pretty-compact';
import { Drawer } from '@metarouter-private/mr-mui';
import RequestHeaders from './RequestHeaders';
import RequestBody from './RequestBody';

export default function RequestDrawer({ requestHeaders, requestBody, open, onClose }) {
  const decodedHeaders = base64.decode(requestHeaders);

  const decodedBody = base64.decode(requestBody);
  const formattedBody = decodedBody ? stringify(JSON.parse(decodedBody)) : '';

  return (
    <Drawer anchor="right" onClose={onClose} open={open} width="60%">
      <RequestHeaders value={decodedHeaders} />
      {formattedBody && <RequestBody value={formattedBody} />}
    </Drawer>
  );
}

RequestDrawer.propTypes = {
  requestHeaders: PropTypes.string.isRequired,
  requestBody: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
