import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '@ion/mrdash';
import TextCell from 'app/components/data-table/TextCell';

export default function FormatNumberCell({ number }) {
  const text = number ? formatNumber(number) : null;
  return <TextCell text={text} />;
}

FormatNumberCell.propTypes = {
  number: PropTypes.number.isRequired,
};
