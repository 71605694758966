import permissions from '../../Apollo/permissions';
import useMrMutation from '../../hooks/useMutation';

import { METHOD, PATH } from '../control-api';
import { mutation } from '../control-proxy-mutation-builder';
import { isNil } from 'lodash';
import transformErrors from './transform-errors';

export default function useTransformEvent({ onCompleted, options = {} }) {
  const [executeMutation, response] = useMrMutation(mutation, {
    context: { permission: permissions.playbookTransformation },
    ...options,
    onCompleted: response => {
      if (!onCompleted) {
        return;
      }
      const { data, errors } = response.control_proxy;
      if (!isNil(errors)) {
        onCompleted({ errors: transformErrors(errors) });
      } else {
        onCompleted({ data: JSON.parse(data) });
      }
    },
  });

  const transformEvent = ({ playbook, inputEvent, clusterId }) => {
    return executeMutation({
      variables: {
        path: PATH.TransformEventWithPlaybook,
        method: METHOD.POST,
        body: { playbook: playbook, inputEvent: inputEvent },
        clusterId,
      },
    });
  };
  return [transformEvent, response];
}
