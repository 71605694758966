import React from 'react';
import PropTypes from 'prop-types';

import { FormModal, PlusCircleIcon } from '@metarouter-private/mr-mui';

import { useInsertSchemaLibrary } from '@ion/api';

import SchemaLibraryForm from './shared/SchemaLibraryForm';

const MODAL_CONFIG = {
  title: 'New Schema Library',
  TitleIcon: PlusCircleIcon,
  submitLabel: 'Create Schema Library',
};

export default function CreateSchemaLibraryModal({ open, onClose }) {
  const [createSchemaLibrary] = useInsertSchemaLibrary({ onCompleted: onClose });

  return (
    <FormModal open={open} onClose={onClose} onSubmit={createSchemaLibrary} {...MODAL_CONFIG}>
      <SchemaLibraryForm />
    </FormModal>
  );
}

CreateSchemaLibraryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
