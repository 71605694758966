import { useMutation } from '../../index';
import {
  mutation,
  buildVariables as buildDeleteOrganizationInvitationVariables,
} from './auth0-delete-organization-invitation-mutation-builder';
import permissions from '../../Apollo/permissions';
import { ORGANIZATION_INVITATIONS_QUERY_NAME } from './auth0-organization-invitations-query-builder';

export default function useDeleteOrganizationInvitation({ onCompleted, ...options } = {}) {
  const [executeMutation, response] = useMutation(mutation, {
    context: { permission: permissions.deleteUsers },
    onCompleted: data => {
      if (onCompleted) {
        onCompleted(getResponseBody({ data }));
      }
    },
    refetchQueries: [ORGANIZATION_INVITATIONS_QUERY_NAME],
    ...options,
  });

  const deleteOrganizationInvitation = ({ invitationId }) => {
    return executeMutation({
      variables: buildVariables({ invitationId }),
    }).then(response => {
      return response;
    });
  };

  return [deleteOrganizationInvitation, response];
}

function getResponseBody(response) {
  return response.data.auth0_delete_organization_invitation;
}

export function buildVariables({ invitationId }) {
  return {
    ...buildDeleteOrganizationInvitationVariables({ invitationId }),
  };
}
