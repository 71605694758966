import { gql } from '@apollo/client';

const pipelineSchemaLibrary = gql`
  fragment pipelineSchemaLibrary on pipeline_schema_libraries {
    id
    schemaLibraryVersionId
    pipelineId
  }
`;

export default pipelineSchemaLibrary;
