import React from 'react';
import PropTypes from 'prop-types';

import { FormModal, PlusCircleIcon } from '@metarouter-private/mr-mui';

import { useInsertEventName } from '@ion/api';

import EventForm from './shared/EventForm';

const MODAL_CONFIG = {
  title: 'New Event',
  TitleIcon: PlusCircleIcon,
  submitLabel: 'Create Event',
};

export default function CreateEventModal({ open, onClose }) {
  const [createEventName] = useInsertEventName({ onCompleted: onClose });

  return (
    <FormModal open={open} onClose={onClose} onSubmit={createEventName} {...MODAL_CONFIG}>
      <EventForm />
    </FormModal>
  );
}

CreateEventModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
