import React from 'react';
import PropTypes from 'prop-types';

import { Box, Tooltip } from '@metarouter-private/mr-mui';

const TOOLTIP_PROPS = {
  arrow: true,
  placement: 'top',
};

const BOX_PROPS = {
  component: 'span',
};

export default function WithTooltip({ children, hasPermission, title }) {
  if (!hasPermission) {
    return (
      <Tooltip {...TOOLTIP_PROPS} title={title}>
        <Box {...BOX_PROPS}>{children}</Box>
      </Tooltip>
    );
  }

  return children;
}

WithTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  hasPermission: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
