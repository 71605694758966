import React from 'react';
import PropTypes from 'prop-types';

import { FormSelect, FormFieldContext, Stack } from '@metarouter-private/mr-mui';

import useEventSchemaFormSelectOptions from './useEventSchemaFormSelectOptions';

export default function EventSchemaForm({ eventId, schemaId }) {
  const { eventOptions, schemaOptions } = useEventSchemaFormSelectOptions();

  return (
    <Stack spacing={2}>
      <FormFieldContext name="event" label="Event" defaultValue={eventId} required>
        <FormSelect options={eventOptions} />
      </FormFieldContext>

      <FormFieldContext name="schema" label="Schema" defaultValue={schemaId} required>
        <FormSelect options={schemaOptions} />
      </FormFieldContext>
    </Stack>
  );
}

EventSchemaForm.propTypes = {
  eventId: PropTypes.string,
  schemaId: PropTypes.string,
};
