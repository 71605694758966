import { gql } from '@apollo/client';
import eventName from 'api/event-names/eventName';

export const mutation = gql`
  mutation UpdateEventName(
    $event_names_pk_columns: event_names_pk_columns_input!
    $event_names_set_input: event_names_set_input!
  ) {
    update_event_names_by_pk(pk_columns: $event_names_pk_columns, _set: $event_names_set_input) {
      ...eventName
    }
  }
  ${eventName}
`;

export function buildVariables({ id, name, description }) {
  return {
    event_names_pk_columns: {
      id,
    },
    event_names_set_input: {
      name,
      description,
    },
  };
}
