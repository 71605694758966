import { gql } from '@apollo/client';
import permissions from 'api/Apollo/permissions';
import useMutation from 'api/hooks/useMutation';
import { query as Pipelines } from 'api/pipelines/useQueryPipelines';
import { query as StagedRevisions } from './useQueryStagedRevisions';
import stagedRevision from './stagedRevision';

const mutation = gql`
  mutation useStageRevisionForDelete($id: uuid!, $stagedForDelete: Boolean!) {
    update_staged_revisions_by_pk(pk_columns: { id: $id }, _set: { stagedForDelete: $stagedForDelete }) {
      ...stagedRevision
    }
  }
  ${stagedRevision}
`;

export default (options = {}) => {
  const [call, response] = useMutation(mutation, {
    ...options,
    context: { permission: permissions.updatePipeline },
    refetchQueries: [
      {
        query: Pipelines,
        context: { permission: permissions.updatePipeline },
      },
      {
        query: StagedRevisions,
        context: { permission: permissions.updatePipeline },
      },
    ],
  });
  return [
    // The update function
    ({ id, stagedForDelete }) => {
      return call({
        variables: {
          id,
          stagedForDelete,
        },
      });
    },
    response,
  ];
};
