import { useQuery } from './../index';
import { gql } from '@apollo/client';

import pipeline from './pipeline';
import permissions from '../Apollo/permissions';
export const query = gql`
  query Pipelines {
    pipelines(where: { deletedAt: { _is_null: true } }, order_by: { name: asc, createdAt: desc }) {
      ...pipeline
    }
  }
  ${pipeline}
`;

export default ({ variables, ...options } = {}) => {
  const { data, loading, isAllowed, error } = useQuery(query, {
    variables, // can apply any transforms or default vars here
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    // TODO (Ohms 2022.01.07) - Correct permission? Should this be readPipelines (plural)
    context: { permission: permissions.readPipeline },
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  return {
    data: data?.pipelines || [],
    loading,
    isAllowed,
    error,
  };
};
