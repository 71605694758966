import React from 'react';
import PropTypes from 'prop-types';

import PipelineSchemaTable from './PipelineSchemaTable';

export default function PipelineSchemaSection({ pipelineSchemaLibraries }) {
  return <PipelineSchemaTable pipelineSchemaLibraries={pipelineSchemaLibraries} />;
}

PipelineSchemaSection.propTypes = {
  pipelineSchemaLibraries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      schemaLibraryVersionId: PropTypes.string.isRequired,
      pipelineId: PropTypes.string.isRequired,
      schemaLibraryVersion: PropTypes.shape({
        id: PropTypes.string.isRequired,
        schemaLibraryId: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        version: PropTypes.number.isRequired,
        schemaLibrary: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          description: PropTypes.string,
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
};
