import React from 'react';
import PropTypes from 'prop-types';
import { Button, PlusCircleIcon } from '@metarouter-private/mr-mui';

import WithTooltip from 'app/components/WithTooltip';
import { useIsVersionEditEnabled } from 'app/schema-enforcement/SchemaLibraryPage/modals/shared/useIsVersionEditEnabled';
import tooltipTitle from 'app/schema-enforcement/SchemaLibraryPage/EventSchemasTable/tooltipTitle';

const TITLE = 'New Event Schema';

export default function NewEventSchemaButton({ onClick }) {
  const hasPermission = useIsVersionEditEnabled();

  return (
    <WithTooltip hasPermission={hasPermission} title={tooltipTitle}>
      <Button startIcon={<PlusCircleIcon />} size="large" disabled={!hasPermission} onClick={onClick}>
        {TITLE}
      </Button>
    </WithTooltip>
  );
}

NewEventSchemaButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
