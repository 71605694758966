/**
 * Generates a SHA-256 hash for the given content.
 * @param {string} content - The content to hash.
 * @returns {Promise<string>} A promise that resolves to the hexadecimal hash string.
 * @throws {Error} If the Crypto API is not supported in the browser.
 */
async function hashFile(content) {
  if (typeof window === 'undefined' || !window.crypto || !window.crypto.subtle) {
    throw new Error('Crypto API is not supported in this environment.');
  }

  const encoder = new TextEncoder();
  const data = encoder.encode(content);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  return Array.from(new Uint8Array(hashBuffer))
    .map(b => b.toString(16).padStart(2, '0'))
    .join('');
}

/**
 * Compares the content of a remote file with a given string.
 * @param {string} url - The URL of the remote file.
 * @param {string} contentString - The string to compare against the remote file.
 * @returns {Promise<boolean>} A promise that resolves to true if the contents are identical, false otherwise.
 * @throws {Error} If there's an error fetching the remote file or comparing contents.
 */
async function compareRemoteFileContentToString(url, contentString) {
  try {
    const response = await fetch(url);
    const remoteContent = (await response.text()).trim();
    const trimContentString = contentString.trim();

    const [hash1, hash2] = await Promise.all([hashFile(remoteContent), hashFile(trimContentString)]);
    return hash1 === hash2;
  } catch (error) {
    throw new Error(`Error comparing file contents: ${error.message}`);
  }
}

export { hashFile };
export default compareRemoteFileContentToString;
