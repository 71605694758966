import { useMutation } from '../../../index';
import permissions from '../../../Apollo/permissions';
import { query as Pipelines } from '../../useQueryPipelines';
import { query as StagedRevisions } from '../useQueryStagedRevisions';

import {
  mutation,
  buildVariables as buildInsertStagedRevisionVariables,
} from './insert-staged-revisions-mutation-builder';

import {
  buildVariables as buildIntegrationAddedToPipelineVariables,
  mutation as integrationCreatedMutation,
} from '../../../audit-logs/integration-added-to-pipeline/audit-log-mutation-builder';
import mergeQueries from '../../../Apollo/merge-queries';

export const combinedMutation = mergeQueries('InsertStagedRevisionAndAudit', [mutation, integrationCreatedMutation]);

export default function useInsertStagedRevision(options = {}) {
  // pipeline ID is not available when called from the playbook editor
  // bail on refetch to prevent an error
  const [executeMutation, response] = useMutation(combinedMutation, {
    ...options,
    context: { permission: permissions.updatePipeline },
    refetchQueries: [
      {
        query: Pipelines,
        context: { permission: permissions.updatePipeline },
      },
      {
        query: StagedRevisions,
        context: { permission: permissions.updatePipeline },
      },
    ],
  });

  const insertStagedRevisionMutation = ({
    pipeline,
    integration,
    playbookId,
    pipelineIntegrationId,
    pipelineVars,
    readyForDeploy,
  }) => {
    return executeMutation({
      variables: buildVariables({
        pipeline,
        integration,
        playbookId,
        pipelineIntegrationId,
        pipelineVars,
        readyForDeploy,
      }),
    });
  };
  return [insertStagedRevisionMutation, response];
}

export function buildVariables({
  pipeline,
  integration,
  playbookId,
  pipelineIntegrationId,
  pipelineVars,
  readyForDeploy,
}) {
  const { id: pipelineId, name: pipelineName } = pipeline;
  const { id: integrationId, name: integrationName } = integration;
  return {
    ...buildInsertStagedRevisionVariables({
      playbookId,
      pipelineIntegrationId,
      pipelineVars,
      readyForDeploy,
    }),
    ...buildIntegrationAddedToPipelineVariables({ pipelineId, pipelineName, integrationId, integrationName }),
  };
}
