import React from 'react';
import PropTypes from 'prop-types';

import TextCell from 'app/components/data-table/TextCell';

export default function NameCell({ name }) {
  return <TextCell text={name} />;
}

NameCell.propTypes = {
  name: PropTypes.string.isRequired,
};
