import { useMutation } from '../../index';
import permissions from '../../Apollo/permissions';
import { buildVariables, mutation } from './update-integration-mutation-builder';

import {
  mutation as integrationUpdated,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/integration-updated/audit-log-mutation-builder';
import mergeQueries from '../../Apollo/merge-queries';
import { validate } from 'parameter-validator';

export const combinedMutation = mergeQueries('UpdateIntegrationAndAudit', [mutation, integrationUpdated]);

export default function useUpdateIntegration(options = {}) {
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Integration updated.',
    context: { permission: permissions.updateIntegration },
    ...options,
  });

  const updateIntegrationMutation = args => {
    const { integrationId } = validate(args, ['integrationId']);
    const { name } = args;

    // TODO [choi]: add validation check, throw errors, catch it with useForm's onSubmit
    if (!name) {
      throw new Error({ param: 'name', msg: 'Nothing to update' });
    }

    return executeMutation({
      variables: {
        ...buildVariables({ integrationId, name }),
        ...buildAuditLogVariables({ integrationId, name }),
      },
    });
  };

  return [updateIntegrationMutation, response];
}
