import React from 'react';
import PropTypes from 'prop-types';

import { ReactDataTable } from '@metarouter-private/mr-mui';

import usePipelineSchemaTableProps from './hooks/usePipelineSchemaTableProps';

export default function PipelineSchemaTable({ pipelineSchemaLibraries }) {
  const pipelineSchemaTableProps = usePipelineSchemaTableProps(pipelineSchemaLibraries);
  return <ReactDataTable {...pipelineSchemaTableProps} />;
}

PipelineSchemaTable.propTypes = {
  pipelineSchemaLibraries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      schemaLibraryVersionId: PropTypes.string.isRequired,
      pipelineId: PropTypes.string.isRequired,
      schemaLibraryVersion: PropTypes.shape({
        id: PropTypes.string.isRequired,
        schemaLibraryId: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        version: PropTypes.number.isRequired,
        schemaLibrary: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          description: PropTypes.string,
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
};
