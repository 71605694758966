import { validate } from 'parameter-validator';

import { useMutation } from '../../index';
import permissions from '../../Apollo/permissions';
import { GET_CONSENT_CATEGORIES } from '../useQueryConsentCategories';

import {
  mutation as removeConsentCategoryMutation,
  buildVariables as buildRemoveConsentCategoriesVariables,
} from './remove-consent-category-mutation-builder';

import {
  consentCategoryDeletedAuditLogMutation,
  buildVariables as buildConsentCategoryDeletedVariables,
} from '../../audit-logs/consent-category-deleted/audit-log-mutation-builder';
import mergeQueries from '../../Apollo/merge-queries';
import useTrackWithUser from '../../hooks/useTrackWithUser';

export const combinedMutation = mergeQueries('RemoveConsentCategoryAndAudit', [
  removeConsentCategoryMutation,
  consentCategoryDeletedAuditLogMutation,
]);

export default function useRemoveConsentCategory(options = {}) {
  const [trackWithUser] = useTrackWithUser('deletes_category');
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Consent Category removed.',
    context: { permission: permissions.deleteConsentCategory },
    refetchQueries: [{ query: GET_CONSENT_CATEGORIES }],
    ...options,
  });

  const removeConsentCategory = args => {
    const { consentCategoryId, name, code } = validate(args, ['consentCategoryId', 'name', 'code']);
    const variables = buildVariables({ consentCategoryId, name, code });
    return executeMutation(variables).then(response => {
      if (!response.errors) {
        trackWithUser({ consentCategoryId, name, code });
      }
    });
  };
  return [removeConsentCategory, response];
}

function buildVariables({ consentCategoryId, name, code }) {
  return {
    variables: {
      ...buildRemoveConsentCategoriesVariables({ consentCategoryId }),
      ...buildConsentCategoryDeletedVariables({ consentCategoryId, name, code }),
    },
  };
}
