import { gql } from '@apollo/client';

const eventName = gql`
  fragment eventName on event_names {
    id
    description
    name
  }
`;

export default eventName;
