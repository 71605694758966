import { gql } from '@apollo/client';

import { useQuery } from 'api';
import permissions from 'api/Apollo/permissions';

const query = gql`
  query SchemaLibraryWithVersions($id: uuid!) {
    schemaLibrary: schema_libraries_by_pk(id: $id) {
      name
      description
      versions: schema_library_versions(order_by: { createdAt: desc }) {
        id
        version
        status
      }
    }
  }
`;

export default function useQuerySchemaLibraryWithVersions({ schemaLibraryId, ...options } = {}) {
  const { data, loading, error, isAllowed } = useQuery(query, {
    variables: { id: schemaLibraryId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    context: { permission: permissions.readSchemas },
    ...options,
  });

  return {
    data,
    loading,
    error,
    isAllowed,
  };
}
