import React from 'react';
import TimestampCell from 'app/components/data-table/TimestampCell';
import ViolationTypeCell from './ViolationTypeCell';
import TextCell from 'app/components/data-table/TextCell';
import FormatNumberCell from 'app/components/data-table/FormatNumberCell';

const schemaViolationsDetailsColumns = [
  {
    name: 'Violation Type',
    cell: row => <ViolationTypeCell type={row.type} />,
  },
  {
    name: 'Path',
    selector: row => row.field,
  },
  {
    name: 'Details',
    selector: row => <TextCell text={row.details} />,
    minWidth: '300px',
  },
  {
    name: 'Count',
    selector: row => <FormatNumberCell number={row.count} />,
  },
  {
    name: 'Last Seen',
    cell: row => <TimestampCell timestamp={row.lastSeen} />,
  },
];

export default schemaViolationsDetailsColumns;
