import { useMutation } from '../../index';
import permissions from '../../Apollo/permissions';
import { validate } from 'parameter-validator';
import { query as Pipelines } from '../../pipelines/useQueryPipelines';
import { query as StagedRevisions } from '../../pipelines/staged-revisions/useQueryStagedRevisions';

import {
  mutation,
  buildVariables as buildUpdatePipelineIntegrationVariables,
} from './update-pipeline-integration-mutation-builder';

import mergeQueries from '../../Apollo/merge-queries';

import {
  mutation as pipelineIntegrationUpdatedMutation,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/pipeline-integration-updated/audit-log-mutation-builder';

export const combinedMutation = mergeQueries('UpdatePipelineIntegrationAndAudit', [
  mutation,
  pipelineIntegrationUpdatedMutation,
]);

export default function useUpdatePipelineIntegration(options = {}) {
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Pipeline integration updated.',
    context: { permission: permissions.updatePipeline },
    ...options,
    refetchQueries: [
      {
        query: Pipelines,
        context: { permission: permissions.updatePipeline },
      },
      {
        query: StagedRevisions,
        context: { permission: permissions.updatePipeline },
      },
    ],
  });

  const updatePipelineIntegrationMutation = args => {
    const { stagedForDelete, consentCategoryId } = args;

    const { pipelineIntegration, playbookId, pipelineVars, readyForDeploy } = validate(args, [
      'pipelineIntegration',
      'playbookId',
      'pipelineVars',
      'readyForDeploy',
    ]);
    return executeMutation({
      variables: buildVariables({
        pipelineIntegration,
        playbookId,
        pipelineVars,
        readyForDeploy,
        stagedForDelete,
        consentCategoryId,
      }),
    });
  };
  return [updatePipelineIntegrationMutation, response];
}

export function buildVariables({
  pipelineIntegration,
  playbookId,
  pipelineVars,
  readyForDeploy,
  stagedForDelete,
  consentCategoryId,
}) {
  return {
    ...buildUpdatePipelineIntegrationVariables({
      pipelineIntegration,
      playbookId,
      pipelineVars,
      readyForDeploy,
      stagedForDelete,
      consentCategoryId,
    }),
    ...buildAuditLogVariables(pipelineIntegration),
  };
}
