import { validate } from 'parameter-validator';

import mergeQueries from 'api/Apollo/merge-queries';
import useMutation from 'api/hooks/useMutation';
import permissions from 'api/Apollo/permissions';
import { GET_EVENT_SCHEMAS } from 'api/schema-library-event-schemas/event-schemas-query-builder';

import {
  buildVariables as buildAuditLogVariables,
  eventSchemaDeletedMutation,
} from 'api/audit-logs/event-schema-deleted/audit-log-mutation-builder';

import {
  removeEventSchemaMutation,
  buildVariables as buildRemoveEventSchemaVariables,
} from './remove-event-schema-mutation-builder';

import useTrackWithUser from 'api/hooks/useTrackWithUser';

export const combinedMutation = mergeQueries('RemoveEventSchemaAndAudit', [
  removeEventSchemaMutation,
  eventSchemaDeletedMutation,
]);

export default function useRemoveEventSchema({ ...options } = {}) {
  const [trackWithUser] = useTrackWithUser('event_schema_deleted');
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Event Schema Removed.',
    context: { permission: permissions.writeSchemaLibraryEventSchemas },
    refetchQueries: [GET_EVENT_SCHEMAS],
    ...options,
  });

  const removeEventSchema = args => {
    const { id, name, description } = validate(args, ['id', 'name', 'description']);
    const variables = buildVariables({ id, name, description });
    return executeMutation(variables).then(response => {
      if (!response.errors) {
        trackWithUser({ id, name, description });
      }
      return response;
    });
  };

  return [removeEventSchema, response];
}

function buildVariables({ id, ...rest }) {
  return {
    variables: {
      ...buildRemoveEventSchemaVariables({ id }),
      ...buildAuditLogVariables({ id, ...rest }),
    },
  };
}
