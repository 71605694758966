import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const SchemaLibraryPageContext = createContext(null);

export default function SchemaLibraryPageContextProvider({ children }) {
  const {
    state: { schemaLibrary },
  } = useLocation();

  return <SchemaLibraryPageContext.Provider value={{ schemaLibrary }}>{children}</SchemaLibraryPageContext.Provider>;
}

export function useSchemaLibraryPageContext() {
  return useContext(SchemaLibraryPageContext);
}

SchemaLibraryPageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
