import React from 'react';
import PropTypes from 'prop-types';

import { Button, PlusCircleIcon } from '@metarouter-private/mr-mui';

import isAllowedToWriteSchemaLibrary from '@ion/api/schema-libraries/isAllowedToWriteSchemaLibrary';

const TITLE = 'New Schema Library';

export default function NewSchemaLibraryButton({ onClick }) {
  const hasPermission = isAllowedToWriteSchemaLibrary();

  return (
    <Button startIcon={<PlusCircleIcon />} size="large" disabled={!hasPermission} onClick={onClick}>
      {TITLE}
    </Button>
  );
}

NewSchemaLibraryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
