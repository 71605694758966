import isAllowedToCreateSchemas from '@ion/api/schemas/permissions/isAllowedToCreateSchemas';

import {
  useSchemaLibraryPageContext,
  SCHEMA_LIBRARY_VERSION_STATUS,
} from 'app/schema-enforcement/SchemaLibraryPage/SchemaLibraryPageContext';

export const useIsVersionEditEnabled = () => {
  const {
    schemaLibrary: {
      latestSchemaLibraryVersion: { status },
    },
  } = useSchemaLibraryPageContext();

  return isAllowedToCreateSchemas() && status === SCHEMA_LIBRARY_VERSION_STATUS.DRAFT;
};
