import { gql } from '@apollo/client';
import buildLimitAndOffset from '../pagination/buildLimitAndOffset';

export const EVENT_NAMES_QUERY = `EventNames`;

export const GET_EVENT_NAMES = gql`
    query ${EVENT_NAMES_QUERY}(
    $limit: Int!
    $offset: Int!
    ) {
        eventNamesAggregate: event_names_aggregate {
            aggregate {
                count
            }
        }
        eventNames: event_names(limit: $limit, offset: $offset, order_by: {updatedAt: asc}) {
            id
            name
            description
            schemaLibraryEventSchemasAggregate: schema_library_event_schemas_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;

export function buildVariables(pagination) {
  return {
    ...buildLimitAndOffset(pagination),
  };
}
