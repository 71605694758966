import { gql } from '@apollo/client';

const schemaLibrary = gql`
  fragment schemaLibrary on schema_libraries {
    id
    orgId
    name
    description
    deletedAt
  }
`;

export default schemaLibrary;
