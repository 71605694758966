import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Typography, Stack, Box, Button, PlusCircleIcon, Trash02Icon } from '@metarouter-private/mr-mui';

import { scrollTimeout } from 'app/constants';
import { PLAYBOOK, PLAYBOOK_PARAMETER } from 'app/playbook-editor/constants';
import { PLAYBOOK_FILTERS_FLAG } from 'app/playbook-editor/parameters/playbook-filters-flag';
import FeatureGate from 'app/launch-darkly/gates/feature-gate';

import DeleteEvent from './modals/delete-event';
import Filters from './filters/Filters';
import NewEvent from './NewEvent';
import Parameter from './parameter';

const BUTTON_PROPS = {
  variant: 'text',
  color: 'secondary',
  size: 'large',
};

const SECTION_NAME = {
  params: 'Parameters',
  global: 'Global',
  eventSpecific: 'Event Specific',
  default: 'Default',
};

const EditorPanel = ({ navState, playbookState, isAllowed }) => {
  //Get the array operations from the nav State
  let operations = playbookState.getOperationsFromSection(navState.selectedSection);
  if (navState.selectedSection === PLAYBOOK.EVENT_SPECIFIC) {
    operations = operations[navState.selectedEvent] ?? [];
  }
  const renderNewOperationCard =
    navState.selectedSection === PLAYBOOK.EVENT_SPECIFIC
      ? playbookState.newParameters.eventSpecific[navState.selectedEvent]
      : playbookState.newParameters[navState.selectedSection];

  const [deleteEventName, setDeleteEventName] = useState(null);
  const isNewEvent = navState.selectedEvent === 'newEvent';

  const isExpanded = operation => {
    return operation.opId === navState.selectedTransformation;
  };

  // scroll the current card into view when selected from the nav menu
  useEffect(() => {
    const element = document.getElementById(navState.selectedTransformation);
    if (element?.scrollIntoView) {
      setTimeout(() => {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }, scrollTimeout);
    }
  }, [navState.selectedTransformation]);

  return (
    <Box width="100%">
      {!isNewEvent && (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} pb={2}>
            <Typography variant="h4">
              {`${navState.sectionNumber}. ${SECTION_NAME[navState.selectedSection]}`}
              {navState.selectedEvent && `: ${navState.selectedEvent}`}
            </Typography>

            <Box display="flex">
              {isAllowed && navState.selectedSection !== PLAYBOOK_PARAMETER.PARAMETER && (
                <Button
                  {...BUTTON_PROPS}
                  startIcon={<PlusCircleIcon />}
                  onClick={() => {
                    playbookState.createNewParameterForSection(navState.selectedSection, navState.selectedEvent);
                    navState.setTransformation('newParam');
                  }}
                >
                  Add Parameter
                </Button>
              )}

              {isAllowed && navState.selectedSection === PLAYBOOK.EVENT_SPECIFIC && (
                <Button
                  {...BUTTON_PROPS}
                  startIcon={<Trash02Icon />}
                  onClick={() => {
                    setDeleteEventName(navState.selectedEvent);
                  }}
                >
                  Delete Event
                </Button>
              )}
            </Box>
          </Stack>

          {navState.selectedSection !== PLAYBOOK_PARAMETER.PARAMETER && (
            <FeatureGate flagName={PLAYBOOK_FILTERS_FLAG}>
              <Filters navState={navState} playbookState={playbookState} />
            </FeatureGate>
          )}

          {operations.map(operation => {
            return (
              <Parameter
                isAllowed={isAllowed}
                playbookState={playbookState}
                navState={navState}
                key={operation.opId}
                operationData={operation}
                isExpanded={isExpanded(operation)}
              />
            );
          })}
        </>
      )}

      {renderNewOperationCard && (
        <Parameter
          isAllowed={isAllowed}
          playbookState={playbookState}
          navState={navState}
          isExpanded
          operationData={renderNewOperationCard}
        />
      )}

      {isNewEvent && <NewEvent navState={navState} playbookState={playbookState} />}

      {deleteEventName && (
        <DeleteEvent
          playbookState={playbookState}
          deleteEventName={deleteEventName}
          setDeleteEventName={setDeleteEventName}
          navState={navState}
        />
      )}
    </Box>
  );
};

EditorPanel.propTypes = {
  navState: PropTypes.shape({
    selectedSection: PropTypes.string.isRequired,
    selectedEvent: PropTypes.string,
    selectedTransformation: PropTypes.string,
    sectionNumber: PropTypes.number.isRequired,
    setTransformation: PropTypes.func.isRequired,
  }).isRequired,
  playbookState: PropTypes.shape({
    getOperationsFromSection: PropTypes.func,
    newParameters: PropTypes.object.isRequired,
    createNewParameterForSection: PropTypes.func,
  }).isRequired,
  isAllowed: PropTypes.bool.isRequired,
};

export default EditorPanel;
