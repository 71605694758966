// TECH DEBT: Disabling eslint rule until entire package can be refactored
/* eslint-disable filename-export/match-default-export */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import pt from 'prop-types';
import Select from 'react-select';

import { findIndex as _findIndex, isArray as _isArray } from 'lodash';

import DropdownIndicator from '../input-select/dropdown-indicator';
import ValueContainer from './value-container';
import CheckBoxOption from './checkbox-option';
import Group from './group/index';
import GroupHeading from './group/heading';

import styles from '../input-select/input-select.styles';
import useDismissable from '../../hooks/use-dismissable';
import useToggle from '../../hooks/use-toggle';
import useValidators from '../../hooks/use-validators';

import MultiSelectContainer from '../input-select/multi-select-container';
import transformOptions from './transformOptions';

const MrInputMultiSelect = ({
  options = [],
  placeholder,
  defaultValue = [],
  onChange,
  name,
  disabled,
  label,
  validators,
  errorMsg,
  onBlur,
  onFocus,
  helperText,
  className,
  selectedVariant,
  testId = 'MrInputMultiSelect',
  optionName,
  disableOptions,
  alphabetized,
}) => {
  const transformedOptions = useMemo(
    () => transformOptions({ options, alphabetized }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options]
  );

  const selectRef = useRef();
  const outerRef = useRef();

  const [selectedOptions, setSelectedOptions] = useState(defaultValue);
  const [isOpen, onToggle] = useToggle();

  useDismissable(outerRef, () => {
    if (isOpen) {
      onToggle();
    }
  });

  const { uuid } = useValidators({ validators });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSelectedOptionsCallback = useCallback(setSelectedOptions, []);

  useEffect(() => {
    setSelectedOptionsCallback(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedOptions, setSelectedOptionsCallback]);

  return (
    <MultiSelectContainer
      testId={testId}
      outerRef={outerRef}
      className={className}
      label={label}
      helperText={helperText}
      toggleOpen={onToggle}
      disabled={disabled}
      selectedVariant={selectedVariant}
      errorMsg={errorMsg}
      id={uuid}
      value={selectedOptions}
      name={name}
    >
      <Select
        toggleOpen={onToggle}
        optionName={optionName}
        components={{
          DropdownIndicator,
          Option: CheckBoxOption,
          ValueContainer: ValueContainer,
          Group: Group,
          GroupHeading: GroupHeading,
        }}
        options={transformedOptions}
        placeholder={placeholder === true ? `Select...` : placeholder}
        ref={selectRef}
        styles={styles}
        key={uuid}
        value={selectedOptions}
        onChange={selected => {
          if (_isArray(selected)) {
            setSelectedOptionsCallback(selected);
            onChange({
              target: {
                name,
                value: selected,
              },
            });
          }
        }}
        isDisabled={disabled}
        noOptionsMessage={() => `No options available`}
        onBlur={e => {
          if (onBlur) {
            onBlur(e);
          }
          ontoggle();
        }}
        onFocus={onFocus}
        menuIsOpen={!disabled ? isOpen : null}
        // custom props below here
        selectedVariant={selectedVariant}
        hideSelectedOptions={false}
        isMulti={true}
        isClearable={false}
        closeMenuOnSelect={false}
        isOptionSelected={(option, selectValue) => -1 !== _findIndex(selectValue, option)}
        isOptionDisabled={() => disableOptions}
      />
    </MultiSelectContainer>
  );
};

MrInputMultiSelect.propTypes = {
  options: pt.arrayOf(
    pt.shape({
      label: pt.string.isRequired,
      options: pt.arrayOf(
        pt.shape({
          label: pt.string.isRequired,
          value: pt.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  defaultValue: pt.array,
  placeholder: pt.oneOfType([pt.string, pt.bool]),
  onChange: pt.func,
  name: pt.string.isRequired,
  label: pt.string,
  disabled: pt.bool,
  validators: pt.arrayOf(pt.func),
  errorMsg: pt.string,
  onBlur: pt.func,
  onFocus: pt.func,
  helperText: pt.string,
  className: pt.string,
  selectedVariant: pt.oneOf(['green', 'orange']),
  testId: pt.string,
  optionName: pt.string.isRequired,
  disableOptions: pt.bool,
  alphabetized: pt.bool,
};

export default MrInputMultiSelect;
