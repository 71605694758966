import React from 'react';
import ViolationTypeChip from './ViolationTypeChip';
import PropTypes from 'prop-types';

export default function ViolationTypeCell({ type }) {
  return <ViolationTypeChip type={type} />;
}

ViolationTypeCell.propTypes = {
  type: PropTypes.string.isRequired,
};
