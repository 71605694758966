import React from 'react';
import PropTypes from 'prop-types';

import { FormFieldContext, FormSelect } from '@metarouter-private/mr-mui';

const SELECT_LABEL = 'clone from (optional)';
const DATA_TEST_ID = {
  'data-test-id': 'cloneFrom',
};

export default function EventSelect({ playbookState, name }) {
  const options = playbookState.getSortedEvents().map(event => {
    return {
      label: event,
      value: event,
    };
  });

  const formSelectFieldProps = {
    name,
    label: SELECT_LABEL,
  };

  const selectProps = {
    options,
    ...DATA_TEST_ID,
  };

  return (
    <FormFieldContext {...formSelectFieldProps}>
      <FormSelect {...selectProps} />
    </FormFieldContext>
  );
}

EventSelect.propTypes = {
  playbookState: PropTypes.shape({
    getSortedEvents: PropTypes.func.isRequired,
  }),
  name: PropTypes.string.isRequired,
};
