import React from 'react';
import { Drawer, Stack } from '@metarouter-private/mr-mui';
import { useSchemaViolationsDrawerContext } from 'app/schema-violations/SchemaViolationsPage/SchemaViolationsTableSection/SchemaViolationsDrawerContextProvider';
import SchemaViolationsDrawerHeader from './SchemaViolationsDrawerHeader';
import SchemaViolationsDetailsTableSection from './SchemaViolationsDetailsTableSection';

export default function SchemaViolationsDrawer() {
  const {
    drawer: { isOpen, onClose },
  } = useSchemaViolationsDrawerContext();

  return (
    <Drawer open={isOpen} onClose={onClose} anchor="right" width="70%">
      <Stack spacing={2}>
        <SchemaViolationsDrawerHeader />
        <SchemaViolationsDetailsTableSection />
      </Stack>
    </Drawer>
  );
}
