import { useQuery } from '../index';
import { gql } from '@apollo/client';
import permissions from '../Apollo/permissions';

const query = gql`
  query IdentitySyncs {
    fetch_identity_syncs {
      status
      data
    }
  }
`;

export default ({ variables, ...options } = {}) => {
  const { data, loading, isAllowed, error } = useQuery(query, {
    variables,
    // TODO (Ohms 2022.01.07) - What is the correct permission?
    context: { permission: permissions.readPipeline },
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  return {
    data: data?.fetch_identity_syncs.data || [],
    loading,
    isAllowed,
    error,
  };
};
