import React from 'react';
import PropTypes from 'prop-types';

import TextCell from 'app/components/data-table/TextCell';

export default function SchemaVersionCell({ schemaVersion }) {
  const version = `Version ${schemaVersion}`;
  return <TextCell text={version} />;
}

SchemaVersionCell.propTypes = {
  schemaVersion: PropTypes.number.isRequired,
};
