import auditLogMutation from 'api/audit-logs/insert-audit-log-mutation';
import { buildAuditLog } from './audit-log-builder';

export const eventSchemaDeletedMutation = auditLogMutation;

export function buildVariables({ id, name, description }) {
  return {
    audit_logs_insert_input: buildAuditLog({
      eventSchemaId: id,
      name,
      description,
    }),
  };
}
