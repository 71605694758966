import useLazyQuery from 'api/hooks/useLazyQuery';
import permissions from 'api/Apollo/permissions';
import { buildVariables, GET_SCHEMA_LIBRARIES } from './schema-libraries-query-builder';

export default function useLazyQuerySchemaLibraries({ ...options } = {}) {
  const [executeQuery, { data, ...rest }] = useLazyQuery(GET_SCHEMA_LIBRARIES, {
    context: { permission: permissions.readSchemas },
    // Not using the cache so we can dynamically refresh whenever a new schema library is created.
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    ...options,
  });

  const getSchemaLibraries = pagination => {
    const variables = buildVariables(pagination);
    return executeQuery({ variables });
  };

  return [getSchemaLibraries, { ...transformData(data), ...rest }];
}

function transformData(data) {
  if (!data) {
    return {
      data: [],
      pagination: { count: null },
    };
  }

  const { schemaLibraries, schemaLibrariesAggregate } = data;

  const transformedSchemaLibraries = schemaLibraries.map(schemaLibrary => {
    const latestSchemaLibraryVersion = schemaLibrary.latestSchemaLibraryVersion[0];

    return {
      ...schemaLibrary,
      latestSchemaLibraryVersion,
    };
  });

  return {
    data: transformedSchemaLibraries,
    pagination: { count: schemaLibrariesAggregate.aggregate.count },
  };
}
