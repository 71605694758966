import { useRemoveEventName } from '@ion/api';

import { useModalContext, MODAL_ACTIONS } from 'app/schema-enforcement/EventsTab/ModalContext';

export default function useConfirmDeleteModal() {
  const {
    modalState: { event, activeModal },
    closeModal,
  } = useModalContext();
  const [removeEventName] = useRemoveEventName({ onCompleted: closeModal });
  const showDeleteModal = !!(event && activeModal === MODAL_ACTIONS.DELETE_EVENT);

  const onConfirm = () => {
    removeEventName({
      id: event.id,
      name: event.name,
      description: event.description,
    });
  };

  return {
    name: event?.name || '',
    showDeleteModal,
    onConfirm,
    onClose: closeModal,
  };
}
