import React from 'react';

import { ConfirmDelete } from '@metarouter-private/mr-mui';

import ConfirmDeleteMessage from './ConfirmDeleteMessage';
import useConfirmDeleteModal from './useConfirmDeleteModal';

export default function ConfirmDeleteModal() {
  const { name, showDeleteModal, onClose, onConfirm } = useConfirmDeleteModal();

  return (
    <ConfirmDelete
      open={showDeleteModal}
      onClose={onClose}
      title={`Delete ${name}`}
      message={<ConfirmDeleteMessage name={name} />}
      onConfirm={onConfirm}
    />
  );
}

ConfirmDeleteModal.propTypes = {};
