/**
 * Builds a URL for accessing AJS in a specific pipeline.
 *
 * @param {string} host - The base URL of the host.
 * @param {string} pipelineId - The unique identifier of the pipeline.
 * @returns {string} A URL string.
 *
 */
const buildMRHostedFileURL = (host, pipelineId) => new URL(`${host}/v1/pipelines/${pipelineId}/file`).toString();

export default buildMRHostedFileURL;
