import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Trash02Icon } from '@metarouter-private/mr-mui';

import WithTooltip from 'app/components/WithTooltip';
import { useIsVersionEditEnabled } from 'app/schema-enforcement/SchemaLibraryPage/modals/shared/useIsVersionEditEnabled';
import { useModalContext } from 'app/schema-enforcement/SchemaLibraryPage/ModalContext';
import tooltipTitle from 'app/schema-enforcement/SchemaLibraryPage/EventSchemasTable/tooltipTitle';

export default function DeleteEventSchemaButton({ row }) {
  const hasPermission = useIsVersionEditEnabled();
  const { deleteEventSchema } = useModalContext();

  const onClick = () => {
    deleteEventSchema(row);
  };

  return (
    <WithTooltip hasPermission={hasPermission} title={tooltipTitle}>
      <IconButton disabled={!hasPermission} data-test-id="deleteEventSchemaButton" onClick={onClick}>
        <Trash02Icon />
      </IconButton>
    </WithTooltip>
  );
}

DeleteEventSchemaButton.propTypes = {
  row: PropTypes.object.isRequired,
};
