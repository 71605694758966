import React from 'react';

import { PageReactDataTable } from '@metarouter-private/mr-mui';

import useEventSchemasTable from './useEventSchemasTable';
import { eventSchemasColumns } from './eventSchemasTableColumns';
import EventSchemasTableActions from './EventSchemasTableActions';
import EventSchemasTableTitle from './EventSchemasTableTitle';

const EVENT_SCHEMAS_PER_PAGE = 10;

export default function EventSchemasTable() {
  const eventSchemasTable = useEventSchemasTable();

  return (
    <PageReactDataTable
      columns={eventSchemasColumns}
      perPage={EVENT_SCHEMAS_PER_PAGE}
      {...eventSchemasTable}
      title={<EventSchemasTableTitle />}
      actions={<EventSchemasTableActions />}
    />
  );
}

EventSchemasTable.propTypes = {};
