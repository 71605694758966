import React from 'react';
import PropTypes from 'prop-types';

import { FormFieldContext, FormTextField } from '@metarouter-private/mr-mui';

const SNAKE_CASE_PATTERN = /^[a-z0-9]+(_[a-z0-9]+)*$/;
const ALPHANUMERIC_PATTERN = /^[_a-zA-Z0-9]+$/;

const ALPHANUMERIC_VALIDATION_MESSAGE = 'Only alphanumeric characters and _ are allowed';
const SNAKE_CASE_VALIDATION_MESSAGE = 'Event names must be lowercase in snake case format (e.g. "test_event_123")';

const EVENT_NAME_FIELD_PROPS = {
  label: 'event name',
  required: true,
  rules: {
    validate: {
      alphanumeric: value => ALPHANUMERIC_PATTERN.test(value) || ALPHANUMERIC_VALIDATION_MESSAGE,
      snakeCase: value => SNAKE_CASE_PATTERN.test(value) || SNAKE_CASE_VALIDATION_MESSAGE,
    },
  },
};

export default function EventField({ name }) {
  const formTextFieldProps = {
    name,
    ...EVENT_NAME_FIELD_PROPS,
  };

  return (
    <FormFieldContext {...formTextFieldProps}>
      <FormTextField />
    </FormFieldContext>
  );
}

EventField.propTypes = {
  name: PropTypes.string.isRequired,
};
