import { useMutation } from '../../index';
import permissions from '../../Apollo/permissions';
import { validate } from 'parameter-validator';
import { query as Pipelines } from '../../pipelines/useQueryPipelines';
import { query as StagedRevisions } from '../../pipelines/staged-revisions/useQueryStagedRevisions';

import {
  mutation as insertPipelineIntegrationMutation,
  buildVariables as buildInsertPipelineIntegrationVariables,
} from './insert-pipeline-integration-mutation-builder';

import {
  mutation as integrationAddedToPipelineMutation,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/integration-added-to-pipeline/audit-log-mutation-builder';

import mergeQueries from '../../Apollo/merge-queries';
import { GET_CONSENT_CATEGORIES_AND_RELATIONSHIPS } from '../../consent-categories/useQueryConsentCategoriesAndRelationships';
import useTrackWithUser from '../../hooks/useTrackWithUser';

export const combinedMutation = mergeQueries('CreatePipelineIntegrationAndAudit', [
  insertPipelineIntegrationMutation,
  integrationAddedToPipelineMutation,
]);

export default function useInsertPipelineIntegration(options = {}) {
  const [trackIntegrationAddedPipeline] = useTrackWithUser('integration_added_to_pipeline');
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Integration added to pipeline.',
    context: { permission: permissions.createPipelineIntegration },
    ...options,
    refetchQueries: [
      {
        query: Pipelines,
        context: { permission: permissions.createPipelineIntegration },
      },
      {
        query: StagedRevisions,
        context: { permission: permissions.createPipelineIntegration },
      },
      {
        query: GET_CONSENT_CATEGORIES_AND_RELATIONSHIPS,
        context: { permission: permissions.readConsentCategory },
      },
    ],
  });

  const insertPipelineIntegrationMutation = args => {
    const { consentCategoryId } = args;
    const { pipeline, integration, playbookId, pipelineVars, readyForDeploy } = validate(args, [
      'pipeline',
      'integration',
      'playbookId',
      'pipelineVars',
      'readyForDeploy',
    ]);
    return executeMutation({
      variables: buildVariables({
        pipeline,
        integration,
        playbookId,
        pipelineVars,
        readyForDeploy,
        consentCategoryId,
      }),
    }).then(response => {
      if (!response.errors) {
        const { starterkitId } = integration;
        trackIntegrationAddedPipeline({
          starterkitId,
        });
      }
      return response;
    });
  };
  return [insertPipelineIntegrationMutation, response];
}

export function buildVariables({ pipeline, integration, playbookId, pipelineVars, readyForDeploy, consentCategoryId }) {
  const { id: pipelineId, name: pipelineName } = pipeline;
  const { id: integrationId, name: integrationName } = integration;

  return {
    ...buildInsertPipelineIntegrationVariables({
      pipelineId,
      integrationId,
      consentCategoryId,
      playbookId,
      pipelineVars,
      readyForDeploy,
    }),
    ...buildAuditLogVariables({ pipelineId, pipelineName, integrationId, integrationName }),
  };
}
