import { gql } from '@apollo/client';
import eventName from 'api/event-names/eventName';

export const mutation = gql`
  mutation InsertEventName($event_names_insert_input: event_names_insert_input!) {
    insert_event_names_one(object: $event_names_insert_input) {
      ...eventName
    }
  }
  ${eventName}
`;

export function buildVariables({ name, description }) {
  return {
    event_names_insert_input: {
      name,
      description,
    },
  };
}
