import { useQueryEventSchemaOptions } from '@ion/api/';

export default function useEventSchemaFormSelectOptions() {
  const { data: eventSchemaOptions } = useQueryEventSchemaOptions();

  const options = {
    eventOptions: mapEventOptions(eventSchemaOptions?.eventNames),
    schemaOptions: mapSchemaOptions(eventSchemaOptions?.schemas),
  };

  return options;
}

function mapEventOptions(eventNames = []) {
  return eventNames.map(event => ({
    label: event.name,
    value: event.id,
  }));
}

function mapSchemaOptions(schemas = []) {
  return schemas.map(schema => ({
    label: schema.name,
    value: schema.latestSchemaVersion[0].id,
  }));
}
