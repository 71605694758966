import violationChipColorCoding from './violationChipColorCoding';

const STATIC_PROPS = {
  variant: 'soft',
};

export default function buildViolationChipProps({ type }) {
  const color = violationChipColorCoding[type];

  return {
    ...STATIC_PROPS,
    label: type,
    color,
  };
}
