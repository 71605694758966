import React from 'react';
import PropTypes from 'prop-types';
import TextCell from 'app/components/data-table/TextCell';

export default function LatestVersionCell({ latestVersion }) {
  const version = latestVersion.version;
  const text = `Version ${version}`;
  return <TextCell text={text} />;
}

LatestVersionCell.propTypes = {
  latestVersion: PropTypes.shape({
    version: PropTypes.number.isRequired,
  }).isRequired,
};
