import React from 'react';
import { useLocation } from 'react-router-dom';
import RedirectFeatureGate from '../../launch-darkly/gates/redirect-feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';
import { MrAppLayout, MrAppMain } from '@ion/components';
import AppHeader from 'app/components/app-header';
import SideNav from 'app/components/side-nav';
import { routes } from 'app/constants';
import { Box, Stack } from '@metarouter-private/mr-mui';
import BackToPipelinesButton from './BackToPipelinesButton';
import SchemaViolationsTableSection from './SchemaViolationsTableSection';

export default function SchemaViolationsPage() {
  const {
    state: { pipeline },
  } = useLocation();

  return (
    <RedirectFeatureGate flagName={FEATURE_FLAGS.intendedSchemaMvp}>
      <MrAppLayout
        title="Schema Enforcement"
        header={
          <AppHeader icon="pipelines" title={pipeline.name} route={routes.pipelines} subTitle="Schema Violations" />
        }
        sideNav={<SideNav />}
      >
        <MrAppMain>
          <Stack spacing={2}>
            <Box>
              <BackToPipelinesButton />
            </Box>
            <Box>
              <SchemaViolationsTableSection />
            </Box>
          </Stack>
        </MrAppMain>
      </MrAppLayout>
    </RedirectFeatureGate>
  );
}
