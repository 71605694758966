import pako from 'pako';

/**
 *
 * @param input
 * @returns {string}
 */
export default ({ input }) => {
  const compressed = pako.gzip(input);
  return btoa(String.fromCharCode.apply(null, compressed));
};
