import React from 'react';

import { MrAppLayout, MrAppMain } from '@ion/components';

import SideNav from 'app/components/side-nav';
import RedirectFeatureGate from 'app/launch-darkly/gates/redirect-feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';

import SchemaLibraryMain from './SchemaLibraryMain';
import SchemaLibraryPageContextProvider from './SchemaLibraryPageContext';
import SchemaLibraryPageHeader from './SchemaLibraryPageHeader';

const SCHEMA_LIBRARY = 'Schema Library';

export default function SchemaLibraryPage() {
  return (
    <RedirectFeatureGate flagName={FEATURE_FLAGS.intendedSchemaMvp}>
      <SchemaLibraryPageContextProvider>
        <MrAppLayout title={SCHEMA_LIBRARY} header={<SchemaLibraryPageHeader />} sideNav={<SideNav />}>
          <MrAppMain>
            <SchemaLibraryMain />
          </MrAppMain>
        </MrAppLayout>
      </SchemaLibraryPageContextProvider>
    </RedirectFeatureGate>
  );
}
