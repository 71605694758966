import { useModalContext } from 'app/schema-enforcement/SchemaLibrariesTab/ModalContext';

export const useBuildMenuItems = row => {
  const { editSchemaLibrary, deleteSchemaLibrary } = useModalContext();
  return [
    {
      label: 'Edit Name or Description',
      onClick: () => editSchemaLibrary(row),
    },
    // {
    //   label: 'Duplicate Schema Library',
    //   onClick: () => console.log('Duplicate Schema Library'),
    // },
    {
      label: 'Delete Schema Library',
      onClick: () => deleteSchemaLibrary(row),
    },
  ];
};
