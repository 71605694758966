import { useInsertEventSchema } from '@ion/api';

export const useCreateEventSchema = (schemaLibraryVersionId, onClose) => {
  const [createEventSchema] = useInsertEventSchema({ onCompleted: onClose });
  const handleCreateEventSchema = ({ event, schema }) => {
    createEventSchema({ schemaLibraryVersionId, eventNameId: event, schemaVersionId: schema });
  };

  return handleCreateEventSchema;
};
