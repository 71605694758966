import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import useActiveModalState from './useActiveModalState';

const ModalContext = createContext(null);

export default function ModalContextProvider({ children }) {
  const { modalState, editEvent, deleteEvent, closeModal } = useActiveModalState();

  return (
    <ModalContext.Provider value={{ modalState, editEvent, deleteEvent, closeModal }}>{children}</ModalContext.Provider>
  );
}

export function useModalContext() {
  return useContext(ModalContext);
}

ModalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
