import React from 'react';
import PropTypes from 'prop-types';

import TextCell from 'app/components/data-table/TextCell';

export default function EventCell({ event }) {
  return <TextCell text={event} />;
}

EventCell.propTypes = {
  event: PropTypes.string.isRequired,
};
