import { query } from './auth0-organizations-query-builder';
import permissions from 'api/Apollo/permissions';
import useLazyQuery from 'api/hooks/useLazyQuery';

export default function useLazyQueryOrganizations({ onCompleted, ...options } = {}) {
  const [executeQuery, { data, ...rest }] = useLazyQuery(query, {
    context: { permission: permissions.overrideOrganization },
    onCompleted: response => {
      if (onCompleted) {
        onCompleted({ ...transformData(response.data), ...response });
      }
    },
    ...options,
  });

  const getOrganizations = pagination => {
    const { page, perPage } = pagination;
    const variables = { page, perPage };
    return executeQuery({ variables });
  };

  return [getOrganizations, { ...transformData(data), ...rest }];
}

function transformData(data) {
  if (!data) {
    return {
      data: [],
      pagination: { count: null },
    };
  }

  const { organizations, total } = data.auth0_organizations;

  return {
    organizations,
    pagination: { count: total },
  };
}
