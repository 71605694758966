import violationTypes from './violationTypes';

const violationChipColorCoding = {
  [violationTypes.GROUP]: 'secondary',
  [violationTypes.TYPE]: 'secondary',
  [violationTypes.REQUIRED]: 'error',
  [violationTypes.ADDITIONAL_PROPERTIES]: 'success',
  [violationTypes.MIN_PROPERTIES]: 'secondary',
  [violationTypes.MAX_PROPERTIES]: 'secondary',
  [violationTypes.MIN_ITEMS]: 'secondary',
  [violationTypes.MAX_ITEMS]: 'secondary',
  [violationTypes.UNIQUE_ITEMS]: 'secondary',
  [violationTypes.MINIMUM]: 'secondary',
  [violationTypes.MAXIMUM]: 'secondary',
  [violationTypes.EXCLUSIVE_MINIMUM]: 'secondary',
  [violationTypes.EXCLUSIVE_MAXIMUM]: 'secondary',
  [violationTypes.MULTIPLE_OF]: 'secondary',
  [violationTypes.ENUM]: 'error',
  [violationTypes.PATTERN]: 'secondary',
  [violationTypes.MIN_LENGTH]: 'secondary',
  [violationTypes.MAX_LENGTH]: 'secondary',
  [violationTypes.CONST]: 'secondary',
  [violationTypes.CONTAINS]: 'secondary',
  [violationTypes.ANY_OF]: 'secondary',
  [violationTypes.ONE_OF]: 'secondary',
  [violationTypes.ALL_OF]: 'secondary',
};

export default violationChipColorCoding;
