import { gql } from '@apollo/client';
import permissions from '../../Apollo/permissions';
import { useMutation } from '../../index';

const MUTATION = gql`
  mutation OverrideOrganization($orgId: String!) {
    auth0_override_organization(orgId: $orgId) {
      status
    }
  }
`;

export default function useOverrideOrganization({ onCompleted, ...options } = {}) {
  const [callMutation, response] = useMutation(MUTATION, {
    context: { permission: permissions.overrideOrganization },
    onCompleted: data => {
      if (onCompleted) {
        onCompleted(getResponseBody({ data }));
      }
    },
    ...options,
  });

  const executeOperation = ({ orgId }) => {
    if (!orgId) {
      return Promise.reject(new Error('orgId is required'));
    }
    return callMutation({
      variables: { orgId },
    });
  };

  function getResponseBody(response) {
    return response.data.auth0_override_organization;
  }

  return [executeOperation, response];
}
