import React from 'react';
import PropTypes from 'prop-types';

import TextCell from 'app/components/data-table/TextCell';

export default function SchemaVersionCell({ version }) {
  const versionText = `Version ${version}`;
  return <TextCell text={versionText} />;
}

SchemaVersionCell.propTypes = {
  version: PropTypes.number.isRequired,
};
