import React from 'react';
import { Button } from '@metarouter-private/mr-mui';
import PropTypes from 'prop-types';
import { useSchemaViolationsDrawerContext } from 'app/schema-violations/SchemaViolationsPage/SchemaViolationsTableSection/SchemaViolationsDrawerContextProvider';

export default function EventNameCell({ row }) {
  const { launchDrawer } = useSchemaViolationsDrawerContext();
  return (
    <Button variant="text" color="secondary" onClick={() => launchDrawer(row)}>
      {row.eventName}
    </Button>
  );
}

EventNameCell.propTypes = {
  row: PropTypes.object.isRequired,
};
