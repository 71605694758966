import mockViolationsTableData from './fixtures/mockViolationsTableData';
import schemaViolationsTableColumns from './schemaViolationsTableColumns';

const STATIC_PROPS = {
  columns: schemaViolationsTableColumns,
};

export default function useSchemaViolationsTableProps() {
  return {
    ...STATIC_PROPS,
    loading: false,
    data: mockViolationsTableData,
  };
}
