import useMutation from 'api/hooks/useMutation';
import permissions from 'api/Apollo/permissions';
import { mutation, buildVariables } from './insert-event-schema-mutation-builder';
import { GET_EVENT_SCHEMAS } from 'api/schema-library-event-schemas/event-schemas-query-builder';

export default function useInsertEventSchema(options = {}) {
  const [executeMutation, response] = useMutation(mutation, {
    ...options,
    refetchQueries: [GET_EVENT_SCHEMAS],
    context: { permission: permissions.createSchemas },
  });

  const insertEventSchemaMutation = ({ schemaLibraryVersionId, eventNameId, schemaVersionId }) => {
    return executeMutation({
      variables: {
        ...buildVariables({
          schemaLibraryVersionId,
          eventNameId,
          schemaVersionId,
        }),
      },
    });
  };

  return [insertEventSchemaMutation, response];
}
