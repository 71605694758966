import React from 'react';
import { ArrowLeftIcon, Button } from '@metarouter-private/mr-mui';
import { Link } from 'react-router-dom';
import { routes } from 'app/constants';

export default function BackToPipelinesButton() {
  return (
    <Button
      component={Link}
      to={routes.pipelines}
      variant="text"
      color="secondary"
      size="large"
      startIcon={<ArrowLeftIcon />}
    >
      Back to pipelines
    </Button>
  );
}
