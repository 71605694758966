import React from 'react';

import EventCell from './cells/EventCell';
import SchemaCell from './cells/SchemaCell';
import ActionsCell from './cells/ActionsCell';
import SchemaVersionCell from './cells/SchemaVersionCell';

export const eventSchemasColumns = [
  {
    name: 'Event',
    cell: row => <EventCell event={row.eventName.name} />,
  },
  {
    name: 'Schema',
    cell: row => <SchemaCell schema={row.schemaVersion.schema.name} />,
  },
  {
    name: 'Schema Version',
    cell: row => <SchemaVersionCell schemaVersion={row.schemaVersion.version} />,
  },
  {
    button: true,
    cell: row => <ActionsCell row={row} />,
  },
];
