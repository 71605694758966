import { gql } from '@apollo/client';
import schemaLibrary from 'api/schema-libraries/schemaLibrary';

export const mutation = gql`
  mutation UpdateSchemaLibrary($id: uuid!, $schema_libraries_set_input: schema_libraries_set_input!) {
    updatedSchemaLibrary: update_schema_libraries_by_pk(pk_columns: { id: $id }, _set: $schema_libraries_set_input) {
      ...schemaLibrary
    }
  }
  ${schemaLibrary}
`;

export function buildVariables({ id, name, description }) {
  return {
    id,
    schema_libraries_set_input: {
      name,
      description,
    },
  };
}
