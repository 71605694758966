import { useReducer } from 'react';

const MODAL_STATE_DEFAULT = { activeModal: null, event: null };

export const MODAL_ACTIONS = {
  EDIT_EVENT: 'EDIT_EVENT',
  DELETE_EVENT: 'DELETE_EVENT',
  CLOSE_MODAL: 'CLOSE_MODAL',
};

export default function useActiveModalState() {
  const [modalState, dispatch] = useReducer(modalReducer, MODAL_STATE_DEFAULT);

  const editEvent = event => {
    dispatch({ type: MODAL_ACTIONS.EDIT_EVENT, event });
  };

  const deleteEvent = event => {
    dispatch({ type: MODAL_ACTIONS.DELETE_EVENT, event });
  };

  const closeModal = () => {
    dispatch({ type: MODAL_ACTIONS.CLOSE_MODAL });
  };

  return { modalState, editEvent, deleteEvent, closeModal };
}

function modalReducer(state, action) {
  switch (action.type) {
    case MODAL_ACTIONS.EDIT_EVENT:
      return {
        activeModal: MODAL_ACTIONS.EDIT_EVENT,
        event: action.event,
      };
    case MODAL_ACTIONS.DELETE_EVENT:
      return {
        activeModal: MODAL_ACTIONS.DELETE_EVENT,
        event: action.event,
      };
    case MODAL_ACTIONS.CLOSE_MODAL:
      return MODAL_STATE_DEFAULT;
    default:
      return state;
  }
}
