import { useUpdateSchemaLibrary } from '@ion/api';

import { useModalContext, MODAL_ACTIONS } from 'app/schema-enforcement/SchemaLibrariesTab/ModalContext';

export default function useEditSchemaLibraryModal() {
  const {
    modalState: { schemaLibrary, activeModal },
    closeModal,
  } = useModalContext();
  const [updateSchemaLibrary] = useUpdateSchemaLibrary({ onCompleted: closeModal });
  const showEditModal = !!(schemaLibrary && activeModal === MODAL_ACTIONS.EDIT_SCHEMA_LIBRARY);

  const onSubmit = ({ name, description }) => {
    updateSchemaLibrary({
      id: schemaLibrary.id,
      name,
      description,
    });
  };

  return {
    name: schemaLibrary?.name || '',
    description: schemaLibrary?.description || '',
    showEditModal,
    onClose: closeModal,
    onSubmit,
  };
}
