import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useSchemaViolationsDrawerContextValue from './useSchemaViolationsDrawerContextValue';

const SchemaViolationsDrawerContext = createContext(null);

export default function SchemaViolationsDrawerContextProvider({ children }) {
  const value = useSchemaViolationsDrawerContextValue();
  return <SchemaViolationsDrawerContext.Provider value={value}>{children}</SchemaViolationsDrawerContext.Provider>;
}

export function useSchemaViolationsDrawerContext() {
  return useContext(SchemaViolationsDrawerContext);
}

SchemaViolationsDrawerContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
