import { useQuery } from '../index';
import { gql } from '@apollo/client';
import permissions from '../Apollo/permissions';
import analyticsFile from './analytics-file';
import { transformResponse } from './transform-response';

const query = gql`
  query AnalyticsFile($pipelineId: uuid!) {
    analytics_files(where: { pipelineId: { _eq: $pipelineId }, _and: { deactivatedAt: { _is_null: true } } }) {
      ...analyticsFile
    }
  }
  ${analyticsFile}
`;

export default ({ variables, ...options } = {}) => {
  const { data, loading, isAllowed, error } = useQuery(query, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-and-network',
    variables,
    // TODO (Ohms 2022.01.07) - What is the correct permission?
    context: { permission: permissions.readPipeline },
    ...options, // see https://www.apollographql.com/docs/react/api/react/hooks/ for options
  });

  return {
    data: transformResponse(data),
    loading,
    isAllowed,
    error,
  };
};
