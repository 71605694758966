import { useLazyQueryEventSchemas } from '@ion/api';

import { useSchemaLibraryPageContext } from 'app/schema-enforcement/SchemaLibraryPage/SchemaLibraryPageContext';

export default function useEventSchemasTable() {
  const { schemaLibrary } = useSchemaLibraryPageContext();
  const { id } = schemaLibrary.latestSchemaLibraryVersion;
  const [getEventSchemas, { data, pagination, loading }] = useLazyQueryEventSchemas();

  return {
    onFetchPage: ({ page, perPage }) => {
      getEventSchemas({
        page,
        perPage,
        schemaLibraryVersionId: id,
      });
    },
    data,
    pagination,
    loading,
  };
}
