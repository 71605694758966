import { useMutation } from '../../index';
import permissions from '../../Apollo/permissions';
import { query as Pipelines } from '../../pipelines/useQueryPipelines';
import { query as StagedRevisions } from '../../pipelines/staged-revisions/useQueryStagedRevisions';

import {
  mutation as pipelineIntegrationRemovedMutation,
  buildVariables as buildRemovePipelineIntegrationVariables,
} from './remove-pipeline-integration-mutation-builder';

import {
  mutation as integrationRemovedFromPipelineMutation,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/integration-removed-from-pipeline/audit-log-mutation-builder';

import mergeQueries from '../../Apollo/merge-queries';

export const combinedMutation = mergeQueries('RemovePipelineIntegrationAndAudit', [
  pipelineIntegrationRemovedMutation,
  integrationRemovedFromPipelineMutation,
]);

export default function useRemovePipelineIntegration(options = {}) {
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Integration removed from pipeline.',
    context: { permission: permissions.deletePipelineIntegration },
    ...options,
    refetchQueries: [
      {
        query: Pipelines,
        context: { permission: permissions.deletePipelineIntegration },
      },
      {
        query: StagedRevisions,
        context: { permission: permissions.deletePipelineIntegration },
      },
    ],
  });

  const removePipelineIntegrationMutation = pipelineIntegration => {
    return executeMutation({
      variables: buildVariables({ pipelineIntegration }),
    });
  };

  return [removePipelineIntegrationMutation, response];
}

export function buildVariables({ pipelineIntegration }) {
  return {
    ...buildRemovePipelineIntegrationVariables({ pipelineIntegrationId: pipelineIntegration.id }),
    ...buildAuditLogVariables({
      id: pipelineIntegration.id,
      pipelineId: pipelineIntegration.pipelineId,
      pipelineName: pipelineIntegration.pipeline.name,
      integrationId: pipelineIntegration.integrationId,
      integrationName: pipelineIntegration.integration.name,
    }),
  };
}
