import { useMutation } from '../../index';
import permissions from '../../Apollo/permissions';
import { validate } from 'parameter-validator';
import mergeQueries from '../../Apollo/merge-queries';
import { useUserContext } from '@ion/user';

import {
  mutation as removePipelineMutation,
  buildVariables as buildRemovePipelineVariables,
} from './remove-pipeline-mutation-builder';

import {
  mutation as pipelineDeletedAuditLogMutation,
  buildVariables as buildPipelineDeletedVariables,
} from '../../audit-logs/pipeline-deleted/audit-log-mutation-builder';

export const combinedMutation = mergeQueries('RemovePipelineAndAudit', [
  removePipelineMutation,
  pipelineDeletedAuditLogMutation,
]);

export default function useRemovePipeline(options = {}) {
  const { user, currentOrg } = useUserContext();
  const userData = { email: user?.email, org: currentOrg?.display_name };
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Pipeline removed.',
    context: { permission: permissions.deletePipeline },
    ...options,
    update: buildUpdateCache(userData),
  });

  const removePipelineMutation = args => {
    const { pipelineId, name } = validate(args, ['pipelineId', 'name']);
    const variables = buildVariables({ pipelineId, name });
    return executeMutation(variables);
  };
  return [removePipelineMutation, response];
}

function buildVariables({ pipelineId, name }) {
  return {
    variables: {
      ...buildRemovePipelineVariables({ pipelineId }),
      ...buildPipelineDeletedVariables({ pipelineId, name }),
    },
  };
}

// Remove from cache on "soft delete"
function buildUpdateCache(userData) {
  return (cache, { data }) => {
    const deletedPipeline = data.update_pipelines_by_pk;

    track({ deletedPipeline, userData });

    // remove it from cache
    cache.evict({ id: cache.identify(data.update_pipelines_by_pk) });
    cache.gc();
  };
}

function track({ deletedPipeline, userData }) {
  __mrTracking?.track('pipeline_deleted', {
    id: deletedPipeline.id,
    userEmail: userData.email,
    orgName: userData.org,
  });
}
