import actions from 'api/audit-logs/actions';
import { generateTimestamp } from 'api/audit-logs/generate-timestamp';

export function buildAuditLog({ schemaLibraryId, name, schemaLibraryDescription }) {
  const action = actions.schema_library_deleted;
  const description = name;
  const debug_data = {
    schemaLibraryId,
    name,
    description: schemaLibraryDescription,
  };
  const timestamp = generateTimestamp();

  return {
    timestamp,
    action,
    description,
    debug_data,
  };
}
