import React from 'react';
import PipelineSchemaTableActions from '../PipelineSchemaTableActions';
import PipelineSchemaTableTitle from '../PipelineSchemaTableTitle';
import pipelineSchemaTableColumns from '../pipelineSchemaTableColumns';

const DEFAULT_COLUMNS = {
  columns: pipelineSchemaTableColumns,
  noDataComponent: null,
  noTableHead: true,
};

export default function usePipelineSchemaTableProps(pipelineSchemaLibraries = []) {
  const tableData = pipelineSchemaLibraries.map(transformLibraryData);

  return {
    ...DEFAULT_COLUMNS,
    actions: <PipelineSchemaTableActions />,
    title: <PipelineSchemaTableTitle />,
    data: tableData,
  };
}

function transformLibraryData({ id, schemaLibraryVersion }) {
  const { schemaLibrary, version } = schemaLibraryVersion;

  const { name, description } = schemaLibrary;

  return {
    id,
    name,
    description,
    version,
  };
}
