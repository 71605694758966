import { gql } from '@apollo/client';
import schemaLibraryVersion from 'api/schema-library-versions/schemaLibraryVersion';
import schemaLibrary from 'api/schema-libraries/schemaLibrary';
import schemaLibraryEventSchema from 'api/schema-library-event-schemas/schemaLibraryEventSchema';

export const mutation = gql`
  mutation InsertSchemaLibraryVersion($schema_library_versions_insert_input: schema_library_versions_insert_input!) {
    insert_schema_library_versions_one(object: $schema_library_versions_insert_input) {
      ...schemaLibraryVersion
      schemaLibrary: schema_library {
        ...schemaLibrary
      }
      schemaLibraryEventSchemas: schema_library_event_schemas {
        ...schemaLibraryEventSchema
      }
    }
  }
  ${schemaLibraryVersion}
  ${schemaLibrary}
  ${schemaLibraryEventSchema}
`;

export function buildVariables({ schemaLibraryId, status = 'DRAFT', version, eventSchemas = [] }) {
  return {
    schema_library_versions_insert_input: {
      schemaLibraryId,
      status,
      version,
      schema_library_event_schemas: {
        data: eventSchemas,
      },
    },
  };
}
