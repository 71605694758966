import React from 'react';
import PropTypes from 'prop-types';

import { FormModal, Edit03Icon } from '@metarouter-private/mr-mui';

import { useUpdateEventName } from '@ion/api';

import EventForm from './shared/EventForm';

const MODAL_CONFIG = {
  title: 'Edit Event',
  TitleIcon: Edit03Icon,
  submitLabel: 'Edit Event',
};

export default function EditEventModal({ id, name, description, open, onClose }) {
  const [updateEventName] = useUpdateEventName({ onCompleted: onClose });

  const onSubmit = ({ name, description }) => {
    updateEventName({ id, name, description });
  };

  return (
    <FormModal open={open} onClose={onClose} onSubmit={onSubmit} {...MODAL_CONFIG}>
      <EventForm name={name} description={description} />
    </FormModal>
  );
}

EditEventModal.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
