import { alphabetizableBy, alphabetizeBy } from '@ion/mrdash';

export default function transformOptions({ options, alphabetized = true }) {
  if (!alphabetized) {
    return options;
  }

  let transformedOptions = options.map(({ options: subOptions, ...rest }) => {
    let transformedSubOptions = subOptions;

    if (alphabetizableBy(subOptions, 'label')) {
      transformedSubOptions = alphabetizeBy(subOptions, 'label');
    }

    return {
      options: transformedSubOptions,
      ...rest,
    };
  });

  if (alphabetizableBy(transformedOptions, 'label')) {
    transformedOptions = alphabetizeBy(transformedOptions, 'label');
  }

  return transformedOptions;
}
