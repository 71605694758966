import React from 'react';
import { Grid, IconButton, Typography, XCloseIcon } from '@metarouter-private/mr-mui';
import { formatNumber } from '@ion/mrdash';
import { useSchemaViolationsDrawerContext } from 'app/schema-violations/SchemaViolationsPage/SchemaViolationsTableSection/SchemaViolationsDrawerContextProvider';

export default function SchemaViolationsDrawerHeader() {
  const {
    drawer: { data, onClose },
  } = useSchemaViolationsDrawerContext();

  return (
    <Grid container direction="row-reverse" justifyContent="space-between" alignItems="center" padding={2}>
      <Grid item>
        <IconButton onClick={onClose}>
          <XCloseIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography variant="h4">{buildHeaderText(data)}</Typography>
      </Grid>
    </Grid>
  );
}

function buildHeaderText(data) {
  let headerText = '';

  if (data) {
    const { violationsCount, eventName } = data;
    headerText = `${formatNumber(violationsCount)} ${eventName} Violations`;
  }
  return headerText;
}
