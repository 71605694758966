import { v4 as uuid } from 'uuid';
import useMutation from 'api/hooks/useMutation';
import permissions from 'api/Apollo/permissions';
import useTrackWithUser from 'api/hooks/useTrackWithUser';
import { GET_SCHEMAS } from 'api/schemas/schemas-query-builder';
import {
  mutation as insertSchemaMutation,
  buildVariables as buildInsertSchemaVariables,
} from './insert-schema-mutation-builder';
import {
  mutation as schemaCreatedMutation,
  buildVariables as buildAuditLogVariables,
} from 'api/audit-logs/schema-created/audit-log-mutation-builder';
import mergeQueries from 'api/Apollo/merge-queries';

export const combinedMutation = mergeQueries('InsertSchemaAndAudit', [insertSchemaMutation, schemaCreatedMutation]);

export default function useInsertSchema(options = {}) {
  const [trackWithUser] = useTrackWithUser('schema_created');
  const [executeMutation, response] = useMutation(combinedMutation, {
    ...options,
    refetchQueries: [GET_SCHEMAS],
    context: { permission: permissions.createSchemas },
  });

  const insertSchemaMutation = ({ name, description, jsonSchema }) => {
    const id = uuid();
    return executeMutation({
      variables: {
        ...buildInsertSchemaVariables({
          id,
          name,
          description,
          jsonSchema,
        }),
        ...buildAuditLogVariables({ schemaId: id, name, description, jsonSchema }),
      },
    }).then(response => {
      if (!response.errors) {
        const id = response.data.insert_schemas_one.id;

        trackWithUser({ id, name, description });
      }
      return response;
    });
  };

  return [insertSchemaMutation, response];
}
