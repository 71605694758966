import { isEmpty as _isEmpty, toString as _toString } from 'lodash';
import { useMutation as useMrMutation } from '../index';
import permissions from '../Apollo/permissions';
import { buildVariables, mutation } from './control-proxy-mutation-builder';
import { METHOD, PATH, PIPELINE_SCOPE } from './control-api';
import mergeQueries from '../Apollo/merge-queries';
import { useUserContext } from '@ion/user';

export function useControlProxy({ onCompleted, additionalQueries = [], ...options }) {
  const combinedMutation = combineMutations(additionalQueries);

  const [executeMutation, response] = useMrMutation(combinedMutation, {
    onCompleted: r => {
      if (onCompleted && r.control_proxy?.data) onCompleted(JSON.parse(r.control_proxy.data));
    },
    context: { permission: options.permission || permissions.deployPipeline },
    ...options,
  });

  const callControlProxy = ({ additionalVariables = {}, ...args }) => {
    const { clusterId, writekey, method, path, body } = args;
    return executeMutation({
      variables: {
        ...buildVariables({ clusterId, writekey, method, path, body }),
        ...additionalVariables,
      },
    }).then(response => {
      // TODO: I'm not sure if we should be hooking into the promise like this
      // because there is already an `onCompleted` function and a `response` object.
      return handleResponse(response);
    });
  };

  return [callControlProxy, response];
}

function combineMutations(additionalQueries) {
  if (!_isEmpty(additionalQueries)) {
    return mergeQueries('useControlProxyCombined', [mutation, ...additionalQueries]);
  }
  return mutation;
}

function handleResponse(response) {
  const data = response.data?.control_proxy?.data;
  if (data) {
    return JSON.parse(data);
  } else {
    return response;
  }
}

const useGetSchemaSummary = (options = {}) => {
  const { user, currentOrg } = useUserContext();
  const userData = { email: user?.email, org: currentOrg?.display_name };
  const [call, response] = useControlProxy({ permission: permissions.readEvents, ...options });
  return [
    ({ clusterId, earliestSeen, timeframe }) => {
      track({ userData, clusterId, timeframe });
      return call({
        path: PATH.GeneratedEventSchemasSummary,
        method: METHOD.GET,
        body: { earliestSeen },
        clusterId,
      });
    },
    response,
  ];

  function track({ userData, clusterId, timeframe }) {
    __mrTracking?.track('schema_report_generated', {
      userEmail: userData.email,
      orgName: userData.org,
      clusterId: clusterId,
      timeframe: timeframe,
    });
  }
};

const useGetGeneratedSchemas = (options = {}) => {
  const [call, response] = useControlProxy({ permission: permissions.readEvents, ...options });
  return [
    ({ clusterId, body }) => {
      return call({
        path: PATH.GeneratedEventSchemas,
        method: METHOD.GET,
        body,
        clusterId,
      });
    },
    response,
  ];
};

const useGetEventSchemaDetails = (options = {}) => {
  const [call, response] = useControlProxy({ permission: permissions.readEvents, ...options });
  return [
    ({ clusterId, eventName, writekeys, earliestSeen, body }) => {
      return call({
        path: PATH.GeneratedEventSchemasDetails,
        method: METHOD.GET,
        body: { eventName, writekeys, earliestSeen, ...body },
        clusterId,
      });
    },
    response,
  ];
};

const useGetEventTraces = (options = {}) => {
  const [call, response] = useControlProxy({ permission: permissions.readEvents, ...options });
  return [
    ({ clusterId, limit }) => {
      return call({
        path: PATH.EventTraces,
        method: METHOD.GET,
        body: { perPage: _toString(limit) },
        clusterId,
      });
    },
    response,
  ];
};

const useDiffPipelines = (options = {}) => {
  const [call, response] = useControlProxy({ permission: permissions.readPipeline, ...options });
  return [
    ({ clusterId, writekey, proposedJSON }) => {
      return call({
        path: PATH.PipelineDiff,
        method: METHOD.POST,
        body: { proposed: proposedJSON, scope: PIPELINE_SCOPE },
        clusterId,
        writekey,
      });
    },
    response,
  ];
};

const useDeletePipeline = (options = {}) => {
  const [call, response] = useControlProxy({ permission: permissions.deletePipeline, ...options });
  return [
    ({ clusterId, writekey }) => {
      return call({
        path: PATH.Pipeline,
        method: METHOD.DELETE,
        body: { scope: PIPELINE_SCOPE, writeKey: writekey },
        clusterId,
        writekey,
      });
    },
    response,
  ];
};

const useGetPipeline = (options = {}) => {
  const [call, response] = useControlProxy({ permission: permissions.readPipeline, ...options });
  return [
    ({ clusterId, writekey }) => {
      return call({
        path: PATH.Pipeline,
        method: METHOD.GET,
        body: { scopes: PIPELINE_SCOPE, writeKey: writekey },
        clusterId,
        writekey,
      });
    },
    response,
  ];
};

const useValidateIntegration = (options = {}) => {
  const [call, response] = useControlProxy({ permission: options.permission, ...options });
  return [
    ({ clusterId, integration }) => {
      return call({
        path: PATH.ValidateIntegration,
        method: METHOD.POST,
        body: { integration },
        clusterId,
      });
    },
    response,
  ];
};

const useCreateAJSFile = (options = {}) => {
  const [call, response] = useControlProxy({ permission: options.permission, ...options });
  return [
    ({ clusterId, pipelineId, gzippedFileContent, email }) => {
      return call({
        path: PATH.CreateAJSFile,
        method: METHOD.POST,
        body: { pipelineId: pipelineId, file: { gzippedFileContent: gzippedFileContent, author: email } },
        clusterId,
      });
    },
    response,
  ];
};

export {
  useDeletePipeline,
  useDiffPipelines,
  useGetEventSchemaDetails,
  useGetEventTraces,
  useGetSchemaSummary,
  useGetGeneratedSchemas,
  useGetPipeline,
  useValidateIntegration,
  useCreateAJSFile,
};
