import isNil from 'lodash/isNil';
import { buildVariables, query } from './search-audit-logs-query-builder';
import permissions from '../../Apollo/permissions';
import { diffInDays } from '@ion/mrdash';

const baseApolloOptions = {
  fetchPolicy: 'network-only',
  nextFetchPolicy: 'cache-and-network',
  context: { permission: permissions.readAuditLogs },
};

export default function searchAuditLogsFetchPage(
  client,
  { email, description, actions, dateRange, sortOrder },
  apolloOptions = {}
) {
  return pagination => {
    const variables = buildVariables({ email, description, actions, dateRange, pagination, sortOrder });

    // ND - TODO - Refactor this to use the useSimpleQuery instead.
    return client
      .query({
        query,
        variables,
        ...{ ...baseApolloOptions, ...apolloOptions },
      })
      .then(response => {
        const audit_logs = response.data.org_audit_logs;
        if (!response.errors && audit_logs.length !== 0) {
          track(audit_logs, variables);
        }
        return transformResponse(response);
      });
  };
}

export function transformResponse(response) {
  if (isNil(response?.data)) {
    return response;
  }

  const auditLogs = response.data.org_audit_logs.map(transformAuditLog);

  return {
    auditLogs,
    pagination: {
      count: response.data.org_audit_logs_aggregate.aggregate.totalCount,
    },
  };
}

function transformAuditLog(auditLog) {
  return {
    ...auditLog,
    timestamp: new Date(auditLog.timestamp),
  };
}

function track(data, variables) {
  // Protection from missing global.
  if (!window.__mrTracking) {
    return;
  }

  const firstAuditLog = data[0];

  const { _and: timestamps, _or: search = [] } = variables.where;

  if (timestamps.length !== 2) {
    return;
  }

  const startDate = timestamps[0]?.timestamp?._gte;
  const endDate = timestamps[1]?.timestamp?._lt;
  const searchQuery = search[0]?.user?.email?._ilike;

  if (!startDate || !endDate) {
    return;
  }

  const dateRange = diffInDays(new Date(startDate), new Date(endDate), 'days');

  const properties = {
    orgName: firstAuditLog?.org?.name,
    userEmail: firstAuditLog?.user?.email,
    ...(searchQuery && { searchTerm: searchQuery?.substr(1, searchQuery?.length - 2) }),
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    isDefaultDateRange: dateRange === 90 ? true : false,
  };

  __mrTracking.track('audit_logged_viewed', properties);
}
