import { useMutation } from 'api';
import permission from 'api/Apollo/permissions';
import { GET_EVENT_NAMES } from 'api/event-names/event-names-query-builder';
import { mutation, buildVariables as buildInsertEventName } from './insert-event-name-mutation-builder';

export default function useInsertEventName(options = {}) {
  const [executeMutation, response] = useMutation(mutation, {
    ...options,
    refetchQueries: [GET_EVENT_NAMES],
    context: { permission: permission.createEventNames },
  });

  const insertEventNameMutation = ({ name, description }) => {
    return executeMutation({
      variables: {
        ...buildInsertEventName({
          name,
          description,
        }),
      },
    });
  };

  return [insertEventNameMutation, response];
}
