import React, { useState } from 'react';
import pt from 'prop-types';

import { MrButton, MrButtonList, MrH, MrInputUpload, MrLink, MrModal } from '@ion/components';
import permissions from '@ion/api/Apollo/permissions';

import styles from './index.module.scss';

import { onSaveRevision } from './on-save-revision';
import { selectFile } from './select-file';

import { useValidateIntegration, useValidationCluster } from '@ion/api';

const UploadModal = ({ insertPlaybook, playbookState, integration, uploadState }) => {
  const [uploadedData, setUploadedData] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [validated, setValidated] = useState(false); // a integration uploaded by the user
  const uploadPermission = permissions[uploadState.uploadPermissionOptions[fileName]];
  const { clusterId } = useValidationCluster();

  const [validate] = useValidateIntegration({
    onCompleted: ({ integration }) => {
      if (integration) {
        uploadState.setError(null);
        setValidated(true);
      }
    },
    onError: ({ errorMsg }) => {
      uploadState.setError(errorMsg);
      setUploadedData(null);
      return;
    },
    permission: uploadPermission,
  });

  return (
    <MrModal closeModal={uploadState.resetModal}>
      <div className={styles.modalHeader}>
        <MrH h="h2" styleNames="sans">
          Upload
        </MrH>
      </div>

      <div className={styles.modalcontent}>
        <>
          <MrInputUpload
            accept=".json, .yml, .yaml"
            onChange={event =>
              selectFile(
                event,
                validate,
                playbookState,
                uploadState,
                integration,
                setFileName,
                setUploadedData,
                clusterId
              )
            }
          />

          {uploadState.error && (
            <div className={styles.errorMsg}>
              <p className="errorMsg">Upload Error: {uploadState.error}</p>
            </div>
          )}

          <MrButtonList
            styleNames="marginTop"
            buttons={[
              <MrButton
                key="save"
                text="Save Revision"
                onClick={() =>
                  onSaveRevision({
                    uploadedData,
                    integration,
                    playbookState,
                    uploadState,
                    insertPlaybook,
                  })
                }
                disabled={Boolean(!validated || uploadState.error)}
              />,
              <MrLink styleNames="gray" key="cancel" onClick={uploadState.resetModal}>
                Cancel
              </MrLink>,
            ]}
          />
        </>
      </div>
    </MrModal>
  );
};

UploadModal.propTypes = {
  insertPlaybook: pt.func.isRequired,
  integration: pt.object.isRequired,
  playbookState: pt.object.isRequired,
  uploadState: pt.shape({
    setError: pt.func.isRequired,
    uploadPermissionOptions: pt.object.isRequired,
    resetModal: pt.func.isRequired,
    error: pt.string,
  }).isRequired,
};

export default UploadModal;
