import React from 'react';
import PropTypes from 'prop-types';

import { FormModal, PlusCircleIcon } from '@metarouter-private/mr-mui';

import { useCreateEventSchema } from './useCreateEventSchema';

import EventSchemaForm from 'app/schema-enforcement/SchemaLibraryPage/modals/shared/EventSchemaForm';

const MODAL_CONFIG = {
  title: 'New Event Schema',
  TitleIcon: PlusCircleIcon,
  submitLabel: 'Create Event Schema',
};

export default function CreateEventSchemaModal({ schemaLibraryVersionId, open, onClose }) {
  const createEventSchema = useCreateEventSchema(schemaLibraryVersionId, onClose);

  return (
    <FormModal open={open} onClose={onClose} onSubmit={createEventSchema} {...MODAL_CONFIG}>
      <EventSchemaForm />
    </FormModal>
  );
}

CreateEventSchemaModal.propTypes = {
  schemaLibraryVersionId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
