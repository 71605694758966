import { Stack, Typography } from '@metarouter-private/mr-mui';
import { MrCodeEditor } from '@ion/components';
import React from 'react';
import PropTypes from 'prop-types';

const SECTION_TITLE = 'Request Headers';

export default function RequestHeaders({ value }) {
  return (
    <Stack padding={3} spacing={2}>
      <Typography variant="h3">{SECTION_TITLE}</Typography>
      <MrCodeEditor rows={30} name="request-drawer-headers" disabled lang="text" value={value} />
    </Stack>
  );
}

RequestHeaders.propTypes = {
  value: PropTypes.string.isRequired,
};
