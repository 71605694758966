import React from 'react';

import { ModalLauncher } from '@metarouter-private/mr-mui';

import CreateSchemaLibraryModal from 'app/schema-enforcement/SchemaLibrariesTab/modals/CreateSchemaLibraryModal';
import NewSchemaLibraryButton from './NewSchemaLibraryButton';

export default function SchemaLibrariesTableActions() {
  return <ModalLauncher Launcher={NewSchemaLibraryButton} Modal={CreateSchemaLibraryModal} />;
}
