export function Store() {
  this.add = () => {};

  this.addNotification = (notification = { title: '', message: '' }) => {
    return this.add(notification);
  };

  this.removeNotification = () => {};

  this.register = ({ addNotification, removeNotification }) => {
    this.add = addNotification;
    this.removeNotification = removeNotification;
  };

  return this;
}

export default new Store();
