import { useRemoveSchemaLibrary } from '@ion/api';

import { useModalContext, MODAL_ACTIONS } from 'app/schema-enforcement/SchemaLibrariesTab/ModalContext';

export default function useConfirmDeleteModal() {
  const {
    modalState: { schemaLibrary, activeModal },
    closeModal,
  } = useModalContext();
  const [removeSchemaLibrary] = useRemoveSchemaLibrary({ onCompleted: closeModal });
  const showDeleteModal = !!(schemaLibrary && activeModal === MODAL_ACTIONS.DELETE_SCHEMA_LIBRARY);

  const onConfirm = () => {
    removeSchemaLibrary({
      id: schemaLibrary.id,
      name: schemaLibrary.name,
      description: schemaLibrary.description,
    });
  };

  return {
    name: schemaLibrary?.name || '',
    showDeleteModal,
    onConfirm,
    onClose: closeModal,
  };
}
