import React from 'react';
import propTypes from 'prop-types';

import { IconButton, Edit03Icon } from '@metarouter-private/mr-mui';

import { useUserModalContext } from '../../../context/UserModalContextProvider';
import isAllowedToEditUser from '../../../permissions/is-allowed-to-edit-user';
import { USER_MODAL_OPTIONS } from '../../../context/user-modal-options';

export default function EditUserButton({ onClick, user }) {
  const { setUserFormModalOption, setSelectedUser } = useUserModalContext();
  const hasPermission = isAllowedToEditUser();

  const handleOnClick = () => {
    setUserFormModalOption(USER_MODAL_OPTIONS.EDIT);
    setSelectedUser(user);
    onClick();
  };

  return (
    <IconButton disabled={!hasPermission} data-test-id="editUserButton" onClick={handleOnClick}>
      <Edit03Icon fontSize="small" />
    </IconButton>
  );
}

EditUserButton.propTypes = {
  onClick: propTypes.func.isRequired,
  user: propTypes.object.isRequired,
};
