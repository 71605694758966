import useMutation from 'api/hooks/useMutation';
import permissions from 'api/Apollo/permissions';
import { mutation, buildVariables } from './insert-schema-library-mutation-builder';
import { GET_SCHEMA_LIBRARIES } from 'api/schema-libraries/schema-libraries-query-builder';

export default function useInsertSchemaLibrary(options = {}) {
  const [executeMutation, response] = useMutation(mutation, {
    ...options,
    refetchQueries: [GET_SCHEMA_LIBRARIES],
    context: { permission: permissions.createSchemas },
  });

  const insertSchemaLibraryMutation = ({ name, description }) => {
    return executeMutation({
      variables: {
        ...buildVariables({
          name,
          description,
        }),
      },
    });
  };

  return [insertSchemaLibraryMutation, response];
}
