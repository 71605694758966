import { useRemoveEventSchema } from '@ion/api';

import { useModalContext, MODAL_ACTIONS } from 'app/schema-enforcement/SchemaLibraryPage/ModalContext';

export default function useConfirmDeleteModal() {
  const {
    modalState: { eventSchema, activeModal },
    closeModal,
  } = useModalContext();
  const [removeEventSchema] = useRemoveEventSchema({ onCompleted: closeModal });
  const showDeleteModal = !!(eventSchema && activeModal === MODAL_ACTIONS.DELETE_EVENT_SCHEMA);

  const onConfirm = () => {
    removeEventSchema({
      id: eventSchema.id,
      name: eventSchema.eventName.name,
      description: eventSchema.eventName.description,
    });
  };

  return {
    name: eventSchema?.eventName?.name || '',
    showDeleteModal,
    onConfirm,
    onClose: closeModal,
  };
}
