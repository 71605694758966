import mockSchemaViolationsDetails from './fixtures/mockSchemaViolationsDetails';
import schemaViolationsDetailsColumns from './schemaViolationsDetailsColumns';

const STATIC_PROPS = {
  pagination: true,
  columns: schemaViolationsDetailsColumns,
};

export default function useSchemaViolationsDetailsTable() {
  return {
    ...STATIC_PROPS,
    loading: false,
    data: mockSchemaViolationsDetails,
  };
}
