import React from 'react';

import { ModalLauncher } from '@metarouter-private/mr-mui';

import CreateEventModal from 'app/schema-enforcement/EventsTab/EventsTable/modals/CreateEventModal';
import NewEventButton from './NewEventButton';

export default function EventsTableActions() {
  return <ModalLauncher Launcher={NewEventButton} Modal={CreateEventModal} />;
}
