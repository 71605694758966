
const mockSchemaViolationsDetails = [
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Type',
    field: 'context.screen.foo',
    details: 'expect String actual Number',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Enum',
    field: 'context.screen.bar',
    details: 'invalid enum value',
    count: 2180038,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
  {
    type: 'Required',
    field: null,
    details: 'property required: [event properties]',
    count: 2304165,
    lastSeen: '2024-12-06T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context',
    details: 'additional properties are not allowed: [userAgent]',
    count: 2183938,
    lastSeen: '2024-12-04T01:50:00Z',
  },
  {
    type: 'AdditionalProperties',
    field: 'context.screen',
    details: 'additional properties are not allowed: [density]',
    count: 2183938,
    lastSeen: '2024-12-03T01:50:00Z',
  },
];

export default mockSchemaViolationsDetails;