import { gql } from '@apollo/client';
import buildLimitAndOffset from 'api/pagination/buildLimitAndOffset';
import schemaLibrary from 'api/schema-libraries/schemaLibrary';
import schemaLibraryVersion from 'api/schema-library-versions/schemaLibraryVersion';

export const GET_SCHEMA_LIBRARIES = gql`
  query SchemaLibraries($limit: Int!, $offset: Int!) {
    schemaLibrariesAggregate: schema_libraries_aggregate {
      aggregate {
        count
      }
    }
    schemaLibraries: schema_libraries(limit: $limit, offset: $offset) {
      ...schemaLibrary
      latestSchemaLibraryVersion: schema_library_versions(order_by: { createdAt: desc }, limit: 1) {
        ...schemaLibraryVersion
      }
    }
  }
  ${schemaLibrary}
  ${schemaLibraryVersion}
`;

export function buildVariables(pagination) {
  return {
    ...buildLimitAndOffset(pagination),
  };
}
