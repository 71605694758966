import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@metarouter-private/mr-mui';
import buildViolationChipProps from './buildViolationChipProps';

export default function ViolationTypeChip({ type }) {
  const props = buildViolationChipProps({ type });
  return <Chip {...props} />;
}

ViolationTypeChip.propTypes = {
  type: PropTypes.string.isRequired,
};
