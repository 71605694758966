import React from 'react';

import { PageReactDataTable } from '@metarouter-private/mr-mui';

import { schemaLibrariesTableColumns } from './schemaLibrariesTableColumns';
import useSchemaLibrariesTable from './useSchemaLibrariesTable';
import SchemaLibrariesTableActions from './SchemaLibrariesTableActions';

const SCHEMA_LIBRARIES_PER_PAGE = 10;

export default function SchemaLibrariesTable() {
  const schemaLibrariesTable = useSchemaLibrariesTable();

  return (
    <PageReactDataTable
      columns={schemaLibrariesTableColumns}
      perPage={SCHEMA_LIBRARIES_PER_PAGE}
      {...schemaLibrariesTable}
      actions={<SchemaLibrariesTableActions />}
    />
  );
}
