import React from 'react';
import PropTypes from 'prop-types';

import { IconButton, Trash02Icon } from '@metarouter-private/mr-mui';
import isAllowedToDeleteSchemas from '@ion/api/schemas/permissions/isAllowedToDeleteSchemas';

import WithTooltip from 'app/components/WithTooltip';
import { useModalContext } from 'app/schema-enforcement/EventsTab/ModalContext';

const TOOLTIP_TITLE = 'To delete, first unassigns the Event from its Schema Libraries.';

export default function DeleteEventButton({ row }) {
  const hasPermission = isAllowedToDeleteSchemas() && row.assignmentsCount === '0';
  const { deleteEvent } = useModalContext();

  const onClick = () => {
    deleteEvent(row);
  };

  return (
    <WithTooltip hasPermission={hasPermission} title={TOOLTIP_TITLE}>
      <IconButton disabled={!hasPermission} data-test-id="deleteEventButton" onClick={onClick}>
        <Trash02Icon />
      </IconButton>
    </WithTooltip>
  );
}

DeleteEventButton.propTypes = {
  row: PropTypes.object.isRequired,
};
