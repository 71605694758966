import React from 'react';

import ActionsCell from './cells/ActionsCell';
import SchemaVersionCell from './cells/SchemaVersionCell';

const pipelineSchemaTableColumns = [
  {
    selector: row => row.name,
  },
  {
    selector: row => row.description,
  },
  {
    cell: row => <SchemaVersionCell version={row.version} />,
  },
  {
    cell: row => <ActionsCell row={row} />,
  },
];

export default pipelineSchemaTableColumns;
