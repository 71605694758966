import React from 'react';
import SchemaViolationsDrawerContextProvider from './SchemaViolationsDrawerContextProvider/SchemaViolationsDrawerContextProvider';
import SchemaViolationsDrawer from './SchemaViolationsDrawer';
import SchemaViolationsTable from './SchemaViolationsTable';

export default function SchemaViolationsTableSection() {
  return (
    <SchemaViolationsDrawerContextProvider>
      <SchemaViolationsTable />
      <SchemaViolationsDrawer />
    </SchemaViolationsDrawerContextProvider>
  );
}
