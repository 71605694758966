import { gql } from '@apollo/client';

export const removeEventNameMutation = gql`
  mutation removeEventName($id: uuid!) {
    update_event_names_by_pk(pk_columns: { id: $id }, _set: { deletedAt: "now()" }) {
      id
    }
  }
`;

export function buildVariables({ id }) {
  return {
    id,
  };
}
