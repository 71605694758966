import { gql } from '@apollo/client';
import pipelineSchemaLibrary from 'api/pipeline-schema-libraries/pipelineSchemaLibrary';

export const upsertPipelineSchemaLibraryMutation = gql`
  mutation UpsertPipelineSchemaLibrary(
    $pipeline_schema_libraries_insert_input: pipeline_schema_libraries_insert_input!
  ) {
    upsertedPipelineSchemaLibrary: insert_pipeline_schema_libraries_one(
      object: $pipeline_schema_libraries_insert_input
      on_conflict: { constraint: pipeline_schema_libraries_pkey, update_columns: [schemaLibraryVersionId] }
    ) {
      ...pipelineSchemaLibrary
    }
  }
  ${pipelineSchemaLibrary}
`;

export function buildVariables({ id, pipelineId, schemaLibraryVersionId }) {
  return {
    pipeline_schema_libraries_insert_input: {
      id,
      pipelineId,
      schemaLibraryVersionId,
    },
  };
}
