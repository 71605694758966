import useRemovePipelineIntegration from 'api/pipeline-integrations/remove/useRemovePipelineIntegration';

export default function useRemovePipelineIntegrations() {
  const [removePipelineIntegration] = useRemovePipelineIntegration();
  const removePipelineIntegrationsMutation = pipelineIntegrations => {
    // TODO (Chris 2024.02.02) - Refactor to remove "forEach" network call loop
    pipelineIntegrations.forEach(pipelineIntegration => {
      removePipelineIntegration(pipelineIntegration);
    });
  };

  return [removePipelineIntegrationsMutation];
}
