import auditLogMutation from '../insert-audit-log-mutation';
import { buildAuditLog } from './audit-log-builder';

export const schemaLibraryAddedMutation = auditLogMutation;

export function buildVariables({ pipelineId, pipelineName, schemaLibraryVersionId, schemaLibraryName }) {
  return {
    audit_logs_insert_input: buildAuditLog({
      pipelineId,
      pipelineName,
      schemaLibraryVersionId,
      schemaLibraryName,
    }),
  };
}
