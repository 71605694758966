import React from 'react';

import ModalContextProvider from 'app/schema-enforcement/SchemaLibrariesTab/ModalContext';
import ConfirmDeleteModal from 'app/schema-enforcement/SchemaLibrariesTab/modals/ConfirmDeleteModal';
import EditSchemaLibraryModal from 'app/schema-enforcement/SchemaLibrariesTab/modals/EditSchemaLibraryModal';
import SchemaLibrariesTable from './SchemaLibrariesTable';

export default function SchemaLibrariesTab() {
  return (
    <ModalContextProvider>
      <SchemaLibrariesTable />
      <EditSchemaLibraryModal />
      <ConfirmDeleteModal />
    </ModalContextProvider>
  );
}
