import { gql } from '@apollo/client';
import schemaLibraryEventSchema from 'api/schema-library-event-schemas/schemaLibraryEventSchema';

export const mutation = gql`
  mutation UpdateEventSchema($id: uuid!, $eventNameId: uuid!, $schemaVersionId: uuid!) {
    update_schema_library_event_schemas_by_pk(
      pk_columns: { id: $id }
      _set: { eventNameId: $eventNameId, schemaVersionId: $schemaVersionId }
    ) {
      ...schemaLibraryEventSchema
    }
  }
  ${schemaLibraryEventSchema}
`;

export function buildVariables({ id, eventNameId, schemaVersionId }) {
  return {
    id,
    eventNameId,
    schemaVersionId,
  };
}
