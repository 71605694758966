import auditLogMutation from 'api/audit-logs/insert-audit-log-mutation';
import { buildAuditLog } from './audit-log-builder';

export const schemaLibraryDeletedMutation = auditLogMutation;

export function buildVariables({ id, name, description }) {
  return {
    audit_logs_insert_input: buildAuditLog({
      schemaLibraryId: id,
      name,
      schemaLibraryDescription: description,
    }),
  };
}
