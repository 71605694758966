import { useMutation } from '../../index';
import { mutation } from './auth0-delete-organization-member-mutation-builder';
import { buildVariables as buildDeleteOrganizationMembersVariables } from './auth0-delete-organization-member-mutation-builder';
import {
  mutation as userDeletedMutation,
  buildVariables as buildAuditLogVariables,
} from '../../audit-logs/user-deleted/audit-log-mutation-builder';
import permissions from '../../Apollo/permissions';
import mergeQueries from '../../Apollo/merge-queries';
import { ORGANIZATION_MEMBERS_QUERY_NAME } from './auth0-organization-members-query-builder';

export const combinedMutation = mergeQueries('DeleteOrganizationMembersAndAudit', [mutation, userDeletedMutation]);

export default function useDeleteOrganizationMembers({ onCompleted, ...options } = {}) {
  const [executeMutation, response] = useMutation(combinedMutation, {
    context: { permission: permissions.deleteUsers },
    onCompleted: data => {
      if (onCompleted) {
        onCompleted(getResponseBody({ data }));
      }
    },
    refetchQueries: [ORGANIZATION_MEMBERS_QUERY_NAME],
    ...options,
  });

  const deleteOrganizationMembers = ({ user }) => {
    return executeMutation({
      variables: buildVariables({ user }),
    });
  };

  return [deleteOrganizationMembers, response];
}

function getResponseBody(response) {
  return response.data.auth0_delete_organization_members;
}

export function buildVariables({ user }) {
  return {
    ...buildDeleteOrganizationMembersVariables({ userIds: [user.id] }),
    ...buildAuditLogVariables({ id: user.id, email: user.email, name: user.name }),
  };
}
