import React from 'react';

import AppHeader from 'app/components/app-header';
import { routes } from 'app/constants';

import { useSchemaLibraryPageContext } from './SchemaLibraryPageContext';

const SCHEMA_ENFORCEMENT = 'Schema Enforcement';

export default function SchemaLibraryPageHeader() {
  const { schemaLibrary } = useSchemaLibraryPageContext();
  const { name, latestSchemaLibraryVersion } = schemaLibrary;
  const { status } = latestSchemaLibraryVersion;
  const subTitle = `${name} Version ${latestSchemaLibraryVersion.version} (${status})`;

  return (
    <AppHeader icon="schemaReport" title={SCHEMA_ENFORCEMENT} route={routes.schemaEnforcement} subTitle={subTitle} />
  );
}

SchemaLibraryPageHeader.propTypes = {};
