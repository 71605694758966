import { gql } from '@apollo/client';
import buildLimitAndOffset from 'api/pagination/buildLimitAndOffset';
import schemaLibraryEventSchema from './schemaLibraryEventSchema';

export const GET_EVENT_SCHEMAS = gql`
  query SchemaLibraryEventSchemas($id: uuid, $limit: Int!, $offset: Int!) {
    schemaLibraryEventSchemasAggregate: schema_library_event_schemas_aggregate(
      where: { schemaLibraryVersionId: { _eq: $id } }
    ) {
      aggregate {
        count
      }
    }
    schemaLibraryEventSchemas: schema_library_event_schemas(
      where: { schemaLibraryVersionId: { _eq: $id } }
      limit: $limit
      offset: $offset
      order_by: { event_name: { name: asc } }
    ) {
      ...schemaLibraryEventSchema
    }
  }
  ${schemaLibraryEventSchema}
`;

export function buildVariables({ schemaLibraryVersionId, page, perPage }) {
  return {
    id: schemaLibraryVersionId,
    ...buildLimitAndOffset({ page, perPage }),
  };
}
