/* eslint-disable filename-export/match-default-export */
/* disabling rule until refactor of analytics builder */

import React, { useEffect, useState } from 'react';
import pt from 'prop-types';

import { useUserContext } from '@ion/user';
import FeatureGate from '@ion/app/src/launch-darkly/gates/feature-gate';
import { MrH, MrCodeEditor, useScrollToTop, saveFile } from '@ion/components';
import { useCreateAJSFile } from '@ion/api';
import permission from '@ion/api/Apollo/permissions';
import compressAndEncode from './compressAndEncode';
import {
  Box,
  Grid,
  Typography,
  Button,
  Download02Icon,
  CheckCircleIcon,
  UploadCloud01Icon,
  EyeIcon,
  EyeOffIcon,
} from '@metarouter-private/mr-mui';
import isFeatureEnabled from '../../launch-darkly/is-feature-enabled';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { HOSTING_TYPES, READABLE_HOSTING_TYPE } from './analytics-builder-file-hosting';
import { useHistory, useLocation } from 'react-router-dom';

import s from './index.module.scss';
import BaseTable from './base-table/BaseTable';
import ConsentCategoryRow from './ConsentCategoryRow';
import { FEATURE_FLAGS } from '../../launch-darkly/featureFlags';
import { INDEXED_DB_INT_VALUE, READABLE_STORAGE_TYPE } from './analytics-builder-advanced-settings';
import useIsGeneratedFileUpdated from './useIsGeneratedFileUpdated';
import buildMRHostedFileURL from './buildMRHostedFileUrl';
import { LoadingButton } from '@metarouter-private/mr-mui/dist/cjs/components';

const SUCCESS_MESSAGE_BUILT = 'Success! Your file has been successfully built. 🎉';
const SUCCESS_MESSAGE_DEPLOYED = 'Success! Your file has been successfully deployed. 🎉';
const FAILURE_MESSAGE = 'Failed to upload file to cluster';
const REVIEW_SETTINGS_MESSAGE = 'Review settings before finalizing deployment';
const DOWNLOAD_PROMPT_MESSAGE =
  'Your Analytics.js file is ready for download. Please deploy it to your environment to make it live.';
const INVALIDATE_CACHE_MESSAGE =
  'To ensure your tracking file changes take effect immediately, invalidate your CDN cache after deployment. Without cache invalidation, changes may take up to 24 hours or more to propagate.';

const getInitialMainText = (fileHostingType, isUploadToClusterEnabled) => {
  return fileHostingType === HOSTING_TYPES.metaRouter && isUploadToClusterEnabled
    ? REVIEW_SETTINGS_MESSAGE
    : SUCCESS_MESSAGE_BUILT;
};

const getInitialSubText = (fileHostingType, isUploadToClusterEnabled) => {
  return fileHostingType === HOSTING_TYPES.metaRouter && isUploadToClusterEnabled
    ? INVALIDATE_CACHE_MESSAGE
    : DOWNLOAD_PROMPT_MESSAGE;
};

const AnalyticsBuilderResults = ({ writekey, resultsData, analyticsSnippet, generatedFile, pipelineName }) => {
  const history = useHistory();
  const {
    location: {
      state: { pipelineId, clusterId },
    },
  } = history;
  const featureFlags = useFlags();
  const isUploadToClusterEnabled = isFeatureEnabled({ featureFlags, flagName: FEATURE_FLAGS.uploadAjsFileToCluster });

  const { pathname } = useLocation();
  const crossDomainResults = resultsData.syncInjectorSettings?.syncs.find(s => s.name === 'crossDomain');
  useScrollToTop(pathname, 'AppLayout');
  const { user, currentOrg } = useUserContext();
  const userData = { email: user?.email, org: currentOrg?.display_name };
  const [disableUploadButton, setDisableUploadButton] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadingComplete, setIsUploadingComplete] = useState(false);
  const [subText, setSubText] = useState(getInitialSubText(resultsData.fileHostingType, isUploadToClusterEnabled));
  const [mainText, setMainText] = useState(getInitialMainText(resultsData.fileHostingType, isUploadToClusterEnabled));
  const [showSnippet, setShowSnippet] = useState(false);

  const { isUpdated: isGeneratedFileUpdated, error: isGeneratedFileUpdatedError } = useIsGeneratedFileUpdated({
    generatedFile,
    remoteFileLocation: buildMRHostedFileURL(resultsData.host, pipelineId),
    hostingType: resultsData.fileHostingType,
  });

  function track({ userData, pipelineName, action }) {
    __mrTracking?.track(action, {
      orgName: userData?.org,
      userEmail: userData?.email,
      pipelineName: pipelineName,
    });
  }

  const syncInjectorDataFiltered = resultsData.syncInjectorSettings?.syncs.filter(s => s.name !== 'crossDomain');
  const isMRHosted = resultsData.fileHostingType === HOSTING_TYPES.metaRouter;

  const handleDownloadClick = () => {
    saveFile({
      compressFile: resultsData.compressFile,
      mimeType: 'text/javascript',
      content: generatedFile,
      filename: resultsData.compressFile ? `${writekey}.js.gz` : `${writekey}.js`,
      compressionType: 'gzip',
    });
    track({ userData, pipelineName, action: 'analytics_file_downloaded' });
  };

  const handleUploadClick = async () => {
    if (isUploadingComplete) return;
    setIsUploading(true);
    setMainText(`Uploading to ${resultsData.host}`);
    setSubText('');

    const startTime = Date.now();
    const MIN_DURATION = 3000;

    try {
      const response = await createAJSFIle({
        clusterId: clusterId,
        pipelineId: pipelineId,
        gzippedFileContent: compressAndEncode({ input: generatedFile }),
        email: userData?.email || 'UI user',
      });

      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, MIN_DURATION - elapsedTime);

      if (remainingTime > 0) {
        await new Promise(resolve => setTimeout(resolve, remainingTime));
      }

      handleUploadResponse(response);
    } catch (error) {
      handleUploadError();
    } finally {
      setIsUploading(false);
      setIsUploadingComplete(true);
    }
  };

  const handleUploadResponse = response => {
    if (response.errors) {
      handleUploadError();
    } else {
      track({ userData, pipelineName, action: 'analytics_file_uploaded_to_cluster' });
      setMainText(SUCCESS_MESSAGE_DEPLOYED);
      setSubText(`Your Analytics.js file is now live and hosted on your Cluster.`);
    }
  };

  const handleSnowShippetClick = () => {
    setShowSnippet(!showSnippet);
  };

  const handleCopySnippetClick = () => {
    navigator.clipboard.writeText(analyticsSnippet);
  };

  const handleUploadError = () => {
    setMainText(FAILURE_MESSAGE);
    setSubText('');
    setIsUploading(false);
  };

  const [createAJSFIle] = useCreateAJSFile({ permission: permission.createAnalyticsJSFile });

  useEffect(() => {
    // if new generated file contents change from MR hosted file, enable upload
    setDisableUploadButton(!isGeneratedFileUpdated);

    // if comparison errors out, continue to allow upload
    if (isGeneratedFileUpdatedError) {
      setDisableUploadButton(false);
    }
  }, [isGeneratedFileUpdated, isGeneratedFileUpdatedError]);

  return (
    <>
      <Box sx={{ mt: 3, p: 3 }} className={s.buttonContainer}>
        <Grid container direction="row" wrap="nowrap" alignItems="center" spacing={8}>
          <Grid item>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: 1 }}>
                <Typography variant="h6">{mainText}</Typography>
                <Typography variant="body2">{subText}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs>
            <Grid direction="row" justifyContent="flex-end" alignItems="center" container wrap="nowrap" spacing={1}>
              <Grid item>
                <Button
                  sx={{
                    px: isMRHosted && isUploadToClusterEnabled ? 2 : 4,
                  }}
                  variant={isMRHosted && isUploadToClusterEnabled ? 'outlined' : 'contained'}
                  color={isMRHosted && isUploadToClusterEnabled ? 'secondary' : 'primary'}
                  size="large"
                  onClick={handleDownloadClick}
                  startIcon={<Download02Icon color={isMRHosted && isUploadToClusterEnabled ? 'action' : 'main'} />}
                >
                  Download
                </Button>
              </Grid>
              {isMRHosted && (
                <FeatureGate flagName={FEATURE_FLAGS.uploadAjsFileToCluster}>
                  <Grid item>
                    <LoadingButton
                      disabled={disableUploadButton}
                      loading={isUploading}
                      sx={{
                        px: 2,
                        whiteSpace: 'nowrap',
                        minWidth: '178px', // Prevent size changes
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      variant="contained"
                      color={isUploadingComplete ? 'success' : 'primary'}
                      size="large"
                      onClick={handleUploadClick}
                      startIcon={!isUploadingComplete && <UploadCloud01Icon color="main" />}
                    >
                      {isUploadingComplete ? <CheckCircleIcon color="main" /> : 'Upload to Cluster'}
                    </LoadingButton>
                  </Grid>
                </FeatureGate>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        className={s.codeSnippetSection}
        sx={{
          mt: 2,
          px: 3,
          py: 2,
        }}
      >
        <Typography variant="h6">Code Snippet</Typography>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          alignItems="center"
          sx={{
            gap: 10,
          }}
        >
          <Grid item xs>
            <Typography sx={{ mt: 1 }} variant="body2">
              The MetaRouter tag should be added near the top of the {'<head>'} tag and before any other script or CSS
              tags.
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
              <Grid item>
                <Button
                  sx={{
                    height: '36px',
                  }}
                  variant="text"
                  color="secondary"
                  size="medium"
                  startIcon={showSnippet ? <EyeOffIcon /> : <EyeIcon />}
                  onClick={handleSnowShippetClick}
                >
                  {showSnippet ? 'Hide' : 'Show'} Snippet
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    height: '36px',
                  }}
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  onClick={handleCopySnippetClick}
                >
                  Copy to Clipboard
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {showSnippet && (
          <pre>
            <MrCodeEditor lang="lua" name="analyticsSnippet" value={analyticsSnippet} />
          </pre>
        )}
      </Box>

      <section className={s.section}>
        <MrH h="h5" sans styleNames="sans noMargin">
          <div className={s.circle}>1</div> File location and settings
        </MrH>
        <div className={s.subSection}>
          <BaseTable>
            <tbody>
              {resultsData.fileHostingType && (
                <tr>
                  <td>Hosting type</td>
                  <td>{READABLE_HOSTING_TYPE[resultsData.fileHostingType]}</td>
                </tr>
              )}
              <tr>
                <td>Analytics.js Hosting URL</td>
                <td>{resultsData.cdnURL}</td>
              </tr>
              <tr>
                <td>Ingestion URL</td>
                <td>{resultsData.host}</td>
              </tr>
              <tr>
                <td>Writekey</td>
                <td>{resultsData.writeKey}</td>
              </tr>
              <tr>
                <td>Compress File</td>
                <td>{resultsData.compressFile ? 'yes' : 'no'}</td>
              </tr>
              <tr>
                <td>Use HTTP cookie</td>
                <td>{resultsData.syncInjectorSettings?.itpSettings?.enabled ? 'yes' : 'no'}</td>
              </tr>
              <FeatureGate flagName={FEATURE_FLAGS.builderServerAdvancedSettings}>
                <tr>
                  <td className={s.setting}>Namespace</td>
                  <td className={s.value}>{resultsData?.windowVariableName}</td>
                </tr>
                <tr>
                  <td className={s.setting}>Storage Prefix</td>
                  <td className={s.value}>{resultsData?.syncInjectorSettings?.storage?.marker}</td>
                </tr>
                <tr>
                  <td className={s.setting}>Storage Type</td>
                  <td className={s.value}>{READABLE_STORAGE_TYPE[resultsData?.syncInjectorSettings?.storage?.type]}</td>
                </tr>
                {resultsData?.syncInjectorSettings?.storage?.type === INDEXED_DB_INT_VALUE && (
                  <tr>
                    <td className={s.setting}>Storage Database</td>
                    <td className={s.value}>{resultsData?.syncInjectorSettings?.storage?.database}</td>
                  </tr>
                )}
              </FeatureGate>
            </tbody>
          </BaseTable>
        </div>
      </section>

      <section className={s.section}>
        <MrH h="h5" sans styleNames="sans noMargin">
          <div className={s.circle}>2</div> Compliance Settings
        </MrH>
        <div className={s.subSection}>
          <BaseTable>
            <tbody>
              <tr>
                <td>Compliance Setting</td>
                <td>
                  {resultsData.syncInjectorSettings?.privacy?.impliedConsent
                    ? 'Implied consent (opt-in by default)'
                    : 'Explicit consent (opt-out by default)'}
                </td>
              </tr>
              <tr>
                <td>Cookie Name</td>
                <td>{resultsData.syncInjectorSettings?.privacy?.cookieName}</td>
              </tr>
            </tbody>
          </BaseTable>
        </div>
      </section>

      <section className={s.section}>
        <MrH h="h5" sans styleNames="sans noMargin">
          <div className={s.circle}>3</div> Identity Syncs
        </MrH>
        <div className={s.subSection}>
          {syncInjectorDataFiltered.map((sync, index) => (
            <div key={index}>
              <MrH h="h6" sans styleNames="sans noMargin">
                {sync.friendlyName}
              </MrH>
              <BaseTable>
                <tbody>
                  <ConsentCategoryRow consentCategoryId={sync.consentCategoryId} />
                  {Object.entries(sync.settings).map(entry => (
                    // setting = entry[0], value = entry[1]
                    <tr key={entry[0]}>
                      <td className={s.setting}>{entry[0]}</td>
                      <td className={s.value}>{typeof entry[1] === 'string' ? entry[1] : JSON.stringify(entry[1])}</td>
                    </tr>
                  ))}
                </tbody>
              </BaseTable>
            </div>
          ))}
        </div>
      </section>

      <FeatureGate flagName="ecmAjsCrossDomainIdentitySync">
        <section className={s.section}>
          <MrH h="h5" sans styleNames="sans noMargin">
            <div className={s.circle}>4</div> Cross-Domain Identity Sync
          </MrH>
          <div className={s.subSection}>
            <div key={'crossDomain'}>
              <BaseTable testId="crossDomainResults">
                <tbody>
                  {crossDomainResults && (
                    <>
                      <ConsentCategoryRow consentCategoryId={crossDomainResults?.consentCategoryId} />
                      <tr>
                        <td className={s.setting}>Urls</td>
                        <td className={s.value}>{crossDomainResults?.settings?.syncURLs.join(', ')}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </BaseTable>
            </div>
          </div>
        </section>
      </FeatureGate>
      <FeatureGate flagName="ecmAjsClientSideIntegrations">
        <section className={s.section}>
          <MrH h="h5" sans styleNames="sans noMargin">
            <div className={s.circle}>&#65290;</div> Client-Side Integrations
          </MrH>
          <div className={s.subSection}>
            {resultsData.integrations?.map((integration, index) => (
              <div key={index}>
                <MrH h="h6" sans styleNames="sans noMargin">
                  {integration.friendlyName}
                </MrH>
                <BaseTable testId="clientSideIntegrationsResults">
                  <tbody>
                    {Object.entries(integration.options).map(entry => (
                      // setting = entry[0], value = entry[1]
                      <tr key={entry[0]}>
                        <td className={s.setting}>{entry[0]}</td>
                        <td className={s.value}>
                          {typeof entry[1] === 'string' ? entry[1] : JSON.stringify(entry[1])}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </BaseTable>
              </div>
            ))}
          </div>
        </section>
      </FeatureGate>
    </>
  );
};

AnalyticsBuilderResults.propTypes = {
  writekey: pt.string,
  resultsData: pt.object,
  analyticsSnippet: pt.string,
  generatedFile: pt.string,
  compressFile: pt.bool,
  pipelineName: pt.string,
};

export default AnalyticsBuilderResults;
