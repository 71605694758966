import { useReducer } from 'react';

const MODAL_STATE_DEFAULT = { activeModal: null, eventSchema: null };

export const MODAL_ACTIONS = {
  EDIT_EVENT_SCHEMA: 'EDIT_EVENT_SCHEMA',
  DELETE_EVENT_SCHEMA: 'DELETE_EVENT_SCHEMA',
  CLOSE_MODAL: 'CLOSE_MODAL',
};

export default function useActiveModalState() {
  const [modalState, dispatch] = useReducer(modalReducer, MODAL_STATE_DEFAULT);

  const editEventSchema = eventSchema => {
    dispatch({ type: MODAL_ACTIONS.EDIT_EVENT_SCHEMA, eventSchema });
  };

  const deleteEventSchema = eventSchema => {
    dispatch({ type: MODAL_ACTIONS.DELETE_EVENT_SCHEMA, eventSchema });
  };

  const closeModal = () => {
    dispatch({ type: MODAL_ACTIONS.CLOSE_MODAL });
  };

  return { modalState, editEventSchema, deleteEventSchema, closeModal };
}

function modalReducer(state, action) {
  switch (action.type) {
    case MODAL_ACTIONS.EDIT_EVENT_SCHEMA:
      return {
        activeModal: MODAL_ACTIONS.EDIT_EVENT_SCHEMA,
        eventSchema: action.eventSchema,
      };
    case MODAL_ACTIONS.DELETE_EVENT_SCHEMA:
      return {
        activeModal: MODAL_ACTIONS.DELETE_EVENT_SCHEMA,
        eventSchema: action.eventSchema,
      };
    case MODAL_ACTIONS.CLOSE_MODAL:
      return MODAL_STATE_DEFAULT;
    default:
      return state;
  }
}
