import React from 'react';
import { Button } from '@metarouter-private/mr-mui';
import { Link } from 'react-router-dom';
import { routes } from 'app/constants';
import { usePipelineCardContext } from 'app/pipelines/card/context/pipeline-card-context';

export default function PipelineSchemaTableActions() {
  const { pipeline } = usePipelineCardContext();

  if (!pipeline) {
    return null;
  }

  const linkProps = {
    component: Link,
    to: {
      pathname: routes.schemaViolations,
      state: { pipeline },
    },
  };

  return (
    <Button variant="text" color="secondary" {...linkProps}>
      Schema Violations
    </Button>
  );
}
