import React from 'react';

import ModalContextProvider from 'app/schema-enforcement/SchemaLibraryPage/ModalContext';
import ConfirmDeleteModal from 'app/schema-enforcement/SchemaLibraryPage/modals/ConfirmDeleteModal';
import EventSchemasTable from './EventSchemasTable';

export default function SchemaLibraryMain() {
  return (
    <ModalContextProvider>
      <EventSchemasTable />
      <ConfirmDeleteModal />
    </ModalContextProvider>
  );
}

SchemaLibraryMain.propTypes = {};
