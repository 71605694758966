import { useMutation } from './../index';
import { gql } from '@apollo/client';
import playbook from './playbook';
import { query } from '../integrations/useQueryIntegrationPlaybooks';
import { query as Integrations } from '../integrations/useQueryIntegrations';
import permissions from '../Apollo/permissions';
import useTrackWithUser from '../hooks/useTrackWithUser';

const mutation = gql`
  mutation CreatePlaybookRevision($objects: [playbooks_insert_input!]!) {
    insert_playbooks(objects: $objects) {
      affected_rows
      returning {
        ...playbook
        integrationId
        pipeline_variables {
          id
          name
          playbookId
        }
        integration {
          starterkitVersion
          name
        }
        staged_revisions {
          playbookId
          pipeline_integration {
            pipeline {
              name
            }
          }
        }
      }
    }
  }
  ${playbook}
`;

export default (options = {}) => {
  const [trackWithUser] = useTrackWithUser('new_integration_revision');
  const [call, response] = useMutation(mutation, {
    successMsg: 'Playbook revision saved.',
    context: { permission: permissions.updateIntegration },
    ...options,
    refetchQueries: [
      {
        query: Integrations,
        variables: { orgId: options.orgId },
        context: { permission: permissions.readIntegration },
      },
    ],
    awaitRefetchQueries: true, // makes sure all queries included in refetchQueries are completed before the mutation is considered complete
    // Update cache on insert
    update: (cache, { data }) => {
      try {
        // get new item from mutation result
        const newItem = data.insert_playbooks.returning[0];

        trackWithUser({
          id: newItem.id,
          integrationId: newItem.integrationId,
          revision: newItem.revision,
        });
        // read query results from cache
        const { integrations_by_pk } =
          cache.readQuery({ query, variables: { integrationId: newItem.integrationId } }) || [];
        const playbooks = integrations_by_pk.playbooks || [];

        // Safety check: find if it already exists, to avoid duplication
        if (~playbooks.findIndex(o => o.id === newItem.id)) return;

        // update the cache with mutation result
        cache.writeQuery({
          query,
          data: {
            integrations_by_pk: {
              ...integrations_by_pk,
              playbooks: [newItem, ...playbooks], // inserts it at beginning
            },
          },
        });
      } catch (e) {
        console.log(e);
        // We should always catch here,
        // as the cache may be empty or the query may fail
      }
    },
  });
  return [
    // The insert function
    playbook => {
      return call({
        variables: {
          objects: playbook,
        },
      });
    },
    response,
  ];
};
