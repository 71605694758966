import React from 'react';
import PipelineIntegrationsSection from './PipelineIntegrationsSection';
import PipelineSchemaSection from './PipelineSchemaSection';
import { Stack } from '@metarouter-private/mr-mui';
import PropTypes from 'prop-types';
import FeatureGate from '../../launch-darkly/gates/feature-gate';
import { FEATURE_FLAGS } from 'app/launch-darkly/featureFlags';

export default function BottomSection({ deployedRevisions, pipeline }) {
  return (
    <Stack spacing={2}>
      <PipelineIntegrationsSection deployedRevisions={deployedRevisions} pipeline={pipeline} />
      <FeatureGate flagName={FEATURE_FLAGS.intendedSchemaMvp}>
        <PipelineSchemaSection pipelineSchemaLibraries={pipeline.pipelineSchemaLibraries} />
      </FeatureGate>
    </Stack>
  );
}

BottomSection.propTypes = {
  deployedRevisions: PropTypes.array.isRequired,
  pipeline: PropTypes.object.isRequired,
};
