import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography } from '@metarouter-private/mr-mui';

import NewEventForm from './NewEventForm';

export default function NewEvent({ navState, playbookState }) {
  return (
    <Stack spacing={2}>
      <Typography variant="h4">3. Event Specific: newEvent</Typography>
      <Typography variant="body1" color="text.secondary">
        What is the incoming event_name from your Analytics.js event stream?
      </Typography>
      <NewEventForm playbookState={playbookState} navState={navState} />
    </Stack>
  );
}

NewEvent.propTypes = {
  navState: PropTypes.shape({
    setEvent: PropTypes.func.isRequired,
  }),
  playbookState: PropTypes.shape({
    createEvent: PropTypes.func.isRequired,
  }),
};
