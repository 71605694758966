import { useReducer } from 'react';

const MODAL_STATE_DEFAULT = { activeModal: null, schemaLibrary: null };

export const MODAL_ACTIONS = {
  EDIT_SCHEMA_LIBRARY: 'EDIT_SCHEMA_LIBRARY',
  DELETE_SCHEMA_LIBRARY: 'DELETE_SCHEMA_LIBRARY',
  CLOSE_MODAL: 'CLOSE_MODAL',
};

export default function useActiveModalState() {
  const [modalState, dispatch] = useReducer(modalReducer, MODAL_STATE_DEFAULT);

  const editSchemaLibrary = schemaLibrary => {
    dispatch({ type: MODAL_ACTIONS.EDIT_SCHEMA_LIBRARY, schemaLibrary });
  };

  const deleteSchemaLibrary = schemaLibrary => {
    dispatch({ type: MODAL_ACTIONS.DELETE_SCHEMA_LIBRARY, schemaLibrary });
  };

  const closeModal = () => {
    dispatch({ type: MODAL_ACTIONS.CLOSE_MODAL });
  };

  return { modalState, editSchemaLibrary, deleteSchemaLibrary, closeModal };
}

function modalReducer(state, action) {
  switch (action.type) {
    case MODAL_ACTIONS.EDIT_SCHEMA_LIBRARY:
      return {
        activeModal: MODAL_ACTIONS.EDIT_SCHEMA_LIBRARY,
        schemaLibrary: action.schemaLibrary,
      };
    case MODAL_ACTIONS.DELETE_SCHEMA_LIBRARY:
      return {
        activeModal: MODAL_ACTIONS.DELETE_SCHEMA_LIBRARY,
        schemaLibrary: action.schemaLibrary,
      };
    case MODAL_ACTIONS.CLOSE_MODAL:
      return MODAL_STATE_DEFAULT;
    default:
      return state;
  }
}
