import { gql } from '@apollo/client';

import eventName from 'api/event-names/eventName';
import schemaVersion from 'api/schemas/schemaVersion';
import schemaLibraryVersion from 'api/schema-library-versions/schemaLibraryVersion';

const schemaLibraryEventSchema = gql`
  fragment schemaLibraryEventSchema on schema_library_event_schemas {
    id
    eventName: event_name {
      ...eventName
    }
    schemaVersion: schema_version {
      ...schemaVersion
      schema {
        name
      }
    }
    schemaLibraryVersion: schema_library_version {
      ...schemaLibraryVersion
    }
  }
  ${eventName}
  ${schemaVersion}
  ${schemaLibraryVersion}
`;

export default schemaLibraryEventSchema;
