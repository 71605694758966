import { useMutation } from '../../../index';
import permissions from '../../../Apollo/permissions';
import { query as Pipelines } from '../../useQueryPipelines';
import { query as StagedRevisions } from '../useQueryStagedRevisions';
import {
  mutation,
  buildVariables as buildRemoveStagedRevisionVariables,
} from './remove-staged-revisions-mutation-builder';

import {
  buildVariables as buildIntegrationRemovedFromPipelineVariables,
  mutation as integrationRemovedMutation,
} from '../../../audit-logs/integration-removed-from-pipeline/audit-log-mutation-builder';
import mergeQueries from '../../../Apollo/merge-queries';

export const combinedMutation = mergeQueries('RemoveStagedRevisionAndAudit', [mutation, integrationRemovedMutation]);

export default function useRemoveStagedRevision(options = {}) {
  const [executeMutation, response] = useMutation(combinedMutation, {
    ...options,
    context: { permission: permissions.updatePipeline },
    refetchQueries: [
      {
        query: Pipelines,
        context: { permission: permissions.updatePipeline },
      },
      {
        query: StagedRevisions,
        context: { permission: permissions.updatePipeline },
      },
    ],
  });

  const removeStagedRevisionMutation = ({ id, pipeline, integration }) => {
    return executeMutation({
      variables: buildVariables({ id, pipeline, integration }),
    });
  };

  return [removeStagedRevisionMutation, response];
}

export function buildVariables({ id, pipeline, integration }) {
  const { id: pipelineId, name: pipelineName } = pipeline;
  const { id: integrationId, name: integrationName } = integration;
  return {
    ...buildRemoveStagedRevisionVariables({ id }),
    ...buildIntegrationRemovedFromPipelineVariables({
      id,
      pipelineId,
      pipelineName,
      integrationId,
      integrationName,
    }),
  };
}
