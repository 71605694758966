import invert from 'lodash/invert';

/*
 * ND - Feel free to move this somewhere more generic or to a
 * different package if needed.
 */

const violationTypes = {
  GROUP: 'Group',
  TYPE: 'Type',
  REQUIRED: 'Required',
  ADDITIONAL_PROPERTIES: 'AdditionalProperties',
  MIN_PROPERTIES: 'MinProperties',
  MAX_PROPERTIES: 'MaxProperties',
  MIN_ITEMS: 'MinItems',
  MAX_ITEMS: 'MaxItems',
  UNIQUE_ITEMS: 'UniqueItems',
  MINIMUM: 'Minimum',
  MAXIMUM: 'Maximum',
  EXCLUSIVE_MINIMUM: 'ExclusiveMinimum',
  EXCLUSIVE_MAXIMUM: 'ExclusiveMaximum',
  MULTIPLE_OF: 'MultipleOf',
  ENUM: 'Enum',
  PATTERN: 'Pattern',
  MIN_LENGTH: 'MinLength',
  MAX_LENGTH: 'MaxLength',
  CONST: 'Const',
  CONTAINS: 'Contains',
  ANY_OF: 'AnyOf',
  ONE_OF: 'OneOf',
  ALL_OF: 'AllOf',
};

export const violationTypeStringToType = invert(violationTypes);

export default violationTypes;
