
const mockViolationsTableData = [
  {
    eventName: 'coupon_added',
    violationsCount: 123456,
    lastSeen: '2024-12-01T03:24:00',
  },
  {
    eventName: 'cart_viewed',
    violationsCount: 654321,
    lastSeen: '2024-12-02T03:24:00',
  }
];

export default mockViolationsTableData;