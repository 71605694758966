import YAML from 'yaml';
import getFiltersAndPath from './get-filters-and-path';
import { useForm } from '@ion/components';
import { isValidYaml } from '@ion/mrdash';
import { PLAYBOOK_STANZA } from '../../../constants';
import useTrackWithUser from '@ion/api/hooks/useTrackWithUser';

export default function useFilters({ navState, playbookState }) {
  const [trackPlaybookFilterApplied] = useTrackWithUser('playbook_filter_applied');
  const { filtersPath, filters } = getFiltersAndPath(navState, playbookState);
  const filtersValue = {
    filters: filters ?? [],
  };

  const formId = PLAYBOOK_STANZA.FILTERS;
  const { submitForm } = useForm(formId);

  const onSubmit = () => {
    const { data } = submitForm();

    const changedFilter = data[filtersPath];

    if (isValidYaml(changedFilter)) {
      playbookState.setFilters(filtersPath, YAML.parse(changedFilter)[PLAYBOOK_STANZA.FILTERS]);
      navState.setTransformation(null);
      trackPlaybookFilterApplied();
    }
  };

  return {
    formId,
    submitForm,
    filtersPath,
    filtersValue,
    onSubmit,
  };
}
