import { useLazyQuerySchemaLibraries } from '@ion/api';

export default function useSchemaLibrariesTable() {
  const [getSchemaLibraries, { data, pagination, loading }] = useLazyQuerySchemaLibraries();

  return {
    onFetchPage: getSchemaLibraries,
    data,
    pagination,
    loading,
  };
}
