import { validate } from 'parameter-validator';

import mergeQueries from 'api/Apollo/merge-queries';
import useMutation from 'api/hooks/useMutation';
import permissions from 'api/Apollo/permissions';
import { GET_SCHEMA_LIBRARIES } from 'api/schema-libraries/schema-libraries-query-builder';

import {
  buildVariables as buildAuditLogVariables,
  schemaLibraryDeletedMutation,
} from 'api/audit-logs/schema-library-deleted/audit-log-mutation-builder';

import {
  removeSchemaLibraryMutation,
  buildVariables as buildRemoveSchemaLibraryVariables,
} from './remove-schema-library-mutation-builder';

import useTrackWithUser from 'api/hooks/useTrackWithUser';

export const combinedMutation = mergeQueries('RemoveSchemaLibraryAndAudit', [
  removeSchemaLibraryMutation,
  schemaLibraryDeletedMutation,
]);

export default function useRemoveSchemaLibrary({ ...options } = {}) {
  const [trackWithUser] = useTrackWithUser('schema_library_deleted');
  const [executeMutation, response] = useMutation(combinedMutation, {
    successMsg: 'Schema Library Removed.',
    context: { permission: permissions.writeSchemaLibrary },
    refetchQueries: [GET_SCHEMA_LIBRARIES],
    ...options,
  });

  const removeSchemaLibrary = args => {
    const { id, name, description } = validate(args, ['id', 'name', 'description']);
    const variables = buildVariables({ id, name, description });
    return executeMutation(variables).then(response => {
      if (!response.errors) {
        trackWithUser({ id, name, description });
      }
      return response;
    });
  };

  return [removeSchemaLibrary, response];
}

function buildVariables({ id, ...rest }) {
  return {
    variables: {
      ...buildRemoveSchemaLibraryVariables({ id }),
      ...buildAuditLogVariables({ id, ...rest }),
    },
  };
}
